export default {
  name: "ConfirmDialog",
  props: {
    title: String,
    body: String,
    openModal: Boolean,
    cancelLabel: String,
    confirmLabel: String,
  },
  methods: {},
};
