import _ from "lodash";
import AccessionEntryActions from "modules/actions/accession-actions";

export default {
  name: "PhysicianInformationSection",
  props: {
    orderingPhysician: {
      type: Object,
      required: true,
    },
    referringPhysician: {
      type: Object,
      required: true,
    },
    orderingPhysicianCopy: {
      type: Object,
      required: true,
    },
    referringPhysicianCopy: {
      type: Object,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      onEditState: false,
      actions: new AccessionEntryActions(),
      isVerifying: {
        ordering: false,
        referring: false,
      },
      rules: {
        required: (val) =>
          (val !== null && val.length !== 0) || "This field is required.",
        validateNpiNumber: async (val, type) => {
          const result = await this.doValidateNpiNumber(val, type);
          return result;
        },
      },
      confirmChangesModal: {
        isVisible: false,
        message: "Are you sure you want to save the updates?",
      },
    };
  },
  computed: {
    __OrderingPhysician: {
      get() {
        return this.orderingPhysician;
      },
      set(value) {
        this.$emit("update:orderingPhysician", value);
      },
    },
    __ReferringPhysician: {
      get() {
        return this.referringPhysician;
      },
      set(value) {
        this.$emit("update:referringPhysician", value);
      },
    },
    referringPhysicianIsAvailable() {
      return this.referringPhysician.id !== null;
    },
    shouldDisableField() {
      return this.isSaving || !this.onEditState;
    },
  },
  methods: {
    async doValidateNpiNumber(value, type) {
      try {
        this.isVerifying[type] = true;

        if (!/^\d{10}$/.test(value)) {
          this.isVerifying[type] = false;
          return "Invalid NPI number.";
        }

        const result = await this.validateNpiNumber(value);
        if (typeof result === "string") {
          this.isVerifying[type] = false;
          return result;
        } else {
          const validateNpiUniqueNess = await this.validateNpiUniqueness(
            value,
            type
          );

          if (typeof validateNpiUniqueNess === "string") {
            return validateNpiUniqueNess;
          }
          this.isVerifying[type] = false;
        }

        this.isVerifying[type] = false;
        return true;
      } catch (error) {
        this.isVerifying[type] = false;
        return "Error validating npi number.";
      }
    },
    async validateNpiUniqueness(npiNumber, prop) {
      const result = await this.actions.searchNpi(npiNumber);
      this.isVerifying[prop] = false;
      const errorMsg = "NPI number already exists.";

      if (result.length === 0) return true;

      if (this[`${prop}Physician`].id !== null) {
        if (result.length > 1) return errorMsg;

        return this[`${prop}Physician`].npi !== null &&
          result[0].id === this[`${prop}Physician`].npi.id
          ? true
          : errorMsg;
      } else {
        return result.length ? errorMsg : true;
      }
    },
    checkFormChanges() {
      const hasChanges = !_.isEqual(this.orderingPhysician, this.orderingPhysicianCopy)
        || !_.isEqual(this.referringPhysician, this.referringPhysicianCopy);
      return hasChanges;
    },
    async savePhysician() {
      this.isSaving = true;
      if (this.checkFormChanges()) {
        this.confirmChangesModal.isVisible = true;
      } else {
        this.showNotifyMessage({
          message: "No changes were made.",
          type: "danger",
        });
        this.isSaving = false;
      }
    },
    async onSave() {
      this.confirmChangesModal.isVisible = false;
      try {
        const validate =
          await this.$refs.physicianInformationForm.validateAsync();
        if (!validate) return;

        const { orderingPhysician, referringPhysician } = this;
        const orderingPhysicianCopy = this.orderingPhysicianCopy;
        const referringPhysicianCopy = this.referringPhysicianCopy;
        orderingPhysician,
          referringPhysician,
          orderingPhysicianCopy,
          referringPhysicianCopy;

        let orderingPhysicianQueries = {};
        let orderingPhysicianChanges = {};
        let referringPhysicianQueries = {};
        let physician = {};

        // update ordering physician
        if (!_.isEqual(orderingPhysician, orderingPhysicianCopy)) {
          // update npi
          if (
            orderingPhysician.npi.npiNumber !==
            orderingPhysicianCopy.npi.npiNumber
          )
            orderingPhysicianQueries["updateNpi"] = {
              npiNumber: {
                type: "String",
                value: orderingPhysician.npi.npiNumber,
              },
            };

          // update name
          if (
            orderingPhysician.firstName !== orderingPhysicianCopy.firstName ||
            orderingPhysician.lastName !== orderingPhysicianCopy.lastName
          ) {
            let updateName = {};

            if (
              orderingPhysician.firstName !== orderingPhysicianCopy.firstName &&
              orderingPhysician.firstName.length
            )
              updateName["firstName"] = {
                type: "String",
                value: orderingPhysician.firstName,
              };

            physician["physician.firstName"] = {
              old: orderingPhysicianCopy.firstName,
              new: orderingPhysician.firstName,
            };

            if (
              orderingPhysician.lastName !== orderingPhysicianCopy.lastName &&
              orderingPhysician.lastName.length
            )
              updateName["lastName"] = {
                type: "String",
                value: orderingPhysician.lastName,
              };

            physician["physician.lastName"] = {
              old: orderingPhysicianCopy.lastName,
              new: orderingPhysician.lastName,
            };

            if (Object.keys(updateName).length)
              orderingPhysicianQueries["updateName"] = updateName;
            orderingPhysicianChanges["physician"] = physician;
          }

          // update taxonomy
          if (orderingPhysician.taxonomy !== orderingPhysicianCopy.taxonomy)
            orderingPhysicianQueries["updateTaxonomy"] = {
              taxonomy: {
                type: "String!",
                value: orderingPhysician.taxonomy,
              },
            };

          await this.actions.updatePhysicianDetails({
            params: {
              physicianId: {
                type: "UUID!",
                value: orderingPhysicianCopy.id,
                unincludeToFields: true,
              },
            },
            queries: orderingPhysicianQueries,
          });
        }

        if (Object.keys(orderingPhysicianChanges).length)
          await this.actions.sendChangeLogNotification({
            params: {
              orderId: {
                type: "UUID!",
                value: this.orderId,
                unincludeToFields: true,
              },
              content: {
                type: "String!",
                value: JSON.stringify(physician),
                unincludeToFields: true,
              },
              permissions: {
                type: "[String]!",
                value: ["KEY_FIELDS_NOTIFICATION"],
                unincludeToFields: true,
              },
            },
          });

        // update referring physician
        if (!_.isEqual(referringPhysician, referringPhysicianCopy)) {
          // update npi
          if (
            referringPhysician.npi.npiNumber !==
            referringPhysicianCopy.npi.npiNumber
          )
            referringPhysicianQueries["updateNpi"] = {
              npiNumber: {
                type: "String",
                value: referringPhysician.npi.npiNumber,
              },
            };

          // update name
          if (
            referringPhysician.firstName !== referringPhysicianCopy.firstName ||
            referringPhysician.lastName !== referringPhysicianCopy.lastName
          ) {
            let updateName = {};

            if (
              referringPhysician.firstName !==
                referringPhysicianCopy.firstName &&
              referringPhysician.firstName.length
            )
              updateName["firstName"] = {
                type: "String",
                value: referringPhysician.firstName,
              };

            if (
              referringPhysician.lastName !== referringPhysicianCopy.lastName &&
              referringPhysician.lastName.length
            )
              updateName["lastName"] = {
                type: "String",
                value: referringPhysician.lastName,
              };

            if (Object.keys(updateName).length)
              referringPhysicianQueries["updateName"] = updateName;
          }

          // update taxonomy
          if (referringPhysician.taxonomy !== referringPhysicianCopy.taxonomy)
            referringPhysicianQueries["updateTaxonomy"] = {
              taxonomy: {
                type: "String!",
                value: referringPhysician.taxonomy,
              },
            };

          await this.actions.updatePhysicianDetails({
            params: {
              physicianId: {
                type: "UUID!",
                value: referringPhysicianCopy.id,
                unincludeToFields: true,
              },
            },
            queries: referringPhysicianQueries,
          });

          this.$emit("update:orderingPhysicianCopy", orderingPhysician);
          this.$emit("update:referringPhysicianCopy", referringPhysician);
        }
        this.showNotifyMessage({
          message: "Added changes saved.",
          type: "success",
        });
      } catch (err) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      } finally {
        this.onEditState = false;
        this.isSaving = false;
      }
    },
    onCancel() {
      this.__OrderingPhysician.npi.npiNumber =
        this.orderingPhysicianCopy.npi.npiNumber;
      this.__OrderingPhysician.firstName = this.orderingPhysicianCopy.firstName;
      this.__OrderingPhysician.lastName = this.orderingPhysicianCopy.lastName;
      this.__OrderingPhysician.taxonomy = this.orderingPhysicianCopy.taxonomy;
      this.__ReferringPhysician.npi.npiNumber =
        this.referringPhysicianCopy.npi.npiNumber;
      this.__ReferringPhysician.firstName =
        this.referringPhysicianCopy.firstName;
      this.__ReferringPhysician.lastName = this.referringPhysicianCopy.lastName;
      this.__ReferringPhysician.taxonomy = this.referringPhysicianCopy.taxonomy;

      this.onEditState = false;
    },
  },
};
