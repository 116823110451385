import _ from "lodash";
import AccessionEntryActions from "modules/actions/accession-actions";
import EnumActions from "store/actions/enum";

export default {
  name: "PatientDemographic",
  components: {},
  props: {
    patient: {
      type: Object,
      required: true,
    },
    patientCopy: {
      type: Object,
      required: true,
    },
    patientSignatureType: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      onEditState: false,
      enumActions: new EnumActions(),
      actions: new AccessionEntryActions(),
      patientSignature: "",
      patientSignatureOptions: [
        {
          text: "Yes",
          value: "Yes",
        },
        {
          text: "No",
          value: "No",
        },
      ],
      genderOptions: [],
      rules: {
        required: val => !["", null, undefined].includes(val) || "This field is required.",
        validateEmail: (val) => {
          if (val === null && val.length === 0) return true;
          const emailPattern = /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
          return emailPattern.test(val) || "Invalid email address";
        },
      },
      confirmChangesModal: {
        isVisible: false,
        message: "Are you sure you want to save the updates?",
      },
    };
  },
  computed: {
    __Patient: {
      get() {
        return this.patient;
      },
      set(value) {
        this.$emit("update:patient", value);
      },
    },
    __PatientSignatureType: {
      get() {
        if (Object.keys(this.patientSignatureType.signatureType).length) {
          return "Yes";
        }
        return "No";
      },
      set(value) {
        this.$emit("update:patientSignatureType", value);
      },
    },
    shouldDisableField() {
      return !this.onEditState || this.isSaving;
    },
  },
  methods: {
    onCancel() {
      this.$refs.patientHomePhone.phoneMask = "";
      this.__Patient.defaultEmail.email = this.patientCopy.defaultEmail.email;
      this.__Patient.addresses.line1 = this.patientCopy.addresses.line1;
      this.__Patient.addresses.country = this.patientCopy.addresses.country;
      this.__Patient.defaultAddress.line1 =
        this.patientCopy.defaultAddress.line1;
      this.__Patient.defaultPhone = { ...this.patientCopy.defaultPhone };
      this.__Patient.defaultAddress.city = this.patientCopy.defaultAddress.city;
      this.__Patient.defaultAddress.stateProvince =
        this.patientCopy.defaultAddress.stateProvince;
      this.__Patient.defaultAddress.country =
        this.patientCopy.defaultAddress.country;
      this.__Patient.defaultAddress.postalCode =
        this.patientCopy.defaultAddress.postalCode;
      this.__Patient.phones = { ...this.patientCopy.phones };
      this.__Patient.info.patientSsn = this.patientCopy.info.patientSsn;
      this.__Patient.defaultAddress.line2 =
        this.patientCopy.defaultAddress.line2;

      this.$nextTick(() => {
        this.__Patient.defaultPhone.areaCode = `(${this.__Patient.defaultPhone.areaCode})`;
        this.$refs.patientHomePhone.phoneMask = "###-####";
      });

      this.onEditState = false;
    },
    checkFormChanges() {
      const hasChanges = !_.isEqual(this.patient, this.patientCopy);
      return hasChanges;
    },
    async savePatientDemographics() {
      this.isSaving = true;
      if (this.checkFormChanges()) {
        this.confirmChangesModal.isVisible = true;
      } else {
        this.showNotifyMessage({
          message: "No changes were made.",
          type: "danger",
        });
        this.isSaving = false;
      }
    },
    async onSave() {
      this.confirmChangesModal.isVisible = false;
      const result = await this.validateSectionForms();
      if (!result) {
        this.showNotifyMessage({
          message: "Please check required and invalid fields",
          type: "danger",
        });
        this.isSaving = false;
        return;
      }
      try {
        const patient = this.patientCopy;
        const patientCopy = this.__Patient;

        // update patient info
        let patientQueries = {};
        let patientCollectionQueries = [];

        //update address
        if (!_.isEqual(patient, patientCopy)) {
          let updateAddress = {
            addressId: {
              type: "UUID!",
              value: patientCopy.defaultAddress.id,
            },
          };

          if (patient.defaultAddress.line1 !== patientCopy.defaultAddress.line1)
            updateAddress["line1"] = {
              type: "String",
              value: patientCopy.defaultAddress.line1,
            };

          if (patient.defaultAddress.line2 !== patientCopy.defaultAddress.line2)
            updateAddress["line2"] = {
              type: "String",
              value: patientCopy.defaultAddress.line2,
            };

          if (patient.defaultAddress.city !== patientCopy.defaultAddress.city)
            updateAddress["city"] = {
              type: "String",
              value: patientCopy.defaultAddress.city,
            };

          if (
            patient.defaultAddress.postalCode !==
            patientCopy.defaultAddress.postalCode
          )
            updateAddress["postalCode"] = {
              type: "String",
              value: patientCopy.defaultAddress.postalCode,
            };

          if (
            patient.defaultAddress.stateProvince !==
            patientCopy.defaultAddress.stateProvince
          )
            updateAddress["stateProvince"] = {
              type: "String",
              value: patientCopy.defaultAddress.stateProvince,
            };

          if (
            patient.defaultAddress.country !==
            patientCopy.defaultAddress.country
          )
            updateAddress["country"] = {
              type: "String",
              value: patientCopy.defaultAddress.country,
            };

          if (Object.keys(updateAddress).length)
            patientQueries["updateAddress"] = updateAddress;
        }

        // update default phone (Home)
        if (patient.defaultPhone.phone !== patientCopy.defaultPhone.phone) {
          if (patientCopy.defaultPhone.id) {
            patientCollectionQueries.push({
              updatePhone: {
                phoneId: {
                  type: "UUID!",
                  value: patientCopy.defaultPhone.id,
                },
                phone: {
                  type: "String",
                  value: this.sanitizePhoneString(
                    patientCopy.defaultPhone.phone
                  ),
                },
                areaCode: {
                  type: "String",
                  value: this.sanitizePhoneString(
                    patientCopy.defaultPhone.areaCode
                  ),
                },
              },
            });
          } else {
            const createPhone = await this.createPhone(
              patientCopy.defaultPhone
            );
            patientCopy.defaultPhone.id = createPhone.id;
            patientCollectionQueries.push({
              addPhone: {
                phoneId: {
                  type: "UUID!",
                  value: createPhone.id,
                },
              },
            });
          }
        }

        // // update phones disabled temporarily
        // if (patient.phones.phone !== patientCopy.phones.phone) {
        //   if (patientCopy.phones.id) {
        //     patientCollectionQueries.push({
        //       updatePhone: {
        //         phoneId: {
        //           type: "UUID!",
        //           value: patientCopy.phones.id,
        //         },
        //         phone: {
        //           type: "String",
        //           value: this.sanitizePhoneString(patientCopy.phones.phone),
        //         },
        //         areaCode: {
        //           type: "String",
        //           value: this.sanitizePhoneString(patientCopy.phones.areaCode),
        //         },
        //       },
        //     });
        //   } else if (patientCopy.phones.phone.length) {
        //     const createPhone = await this.createPhone(patientCopy.phones);
        //     patientCopy.phones.id = createPhone.id;
        //     patientCollectionQueries.push({
        //       addPhone: {
        //         phoneId: {
        //           type: "UUID!",
        //           value: createPhone.id,
        //         },
        //       },
        //     });
        //   }
        // }
        if (
          patient.defaultEmail.email !== patientCopy.defaultEmail.email &&
          patientCopy.defaultEmail.id &&
          patientCopy.defaultEmail.email != ""
        ) {
          patientQueries["updateEmail"] = {
            emailId: {
              type: "UUID!",
              value: patientCopy.defaultEmail.id,
            },
            email: {
              type: "String!",
              value: patientCopy.defaultEmail.email,
            },
          };
        }

        if (patient.info.patientSsn !== patientCopy.info.patientSsn) {
          patientQueries["updatePatientSsn"] = {
            patientSsn: {
              type: "String!",
              value: patientCopy.info.patientSsn,
            },
          };
        }

        await this.actions.updatePatientDetails({
          patientId: {
            type: "UUID!",
            value: patientCopy.info.id,
            unincludeToFields: true,
          },
          queries: patientQueries,
          collectionQueries: patientCollectionQueries,
        });

        this.$emit("update:patientCopy", patientCopy);

        this.showNotifyMessage({
          message: `Added changes saved.`,
          type: "success",
        });
      } catch (err) {
        this.showNotifyMessage({
          message: `Problem has occurred while saving data.`,
          type: "danger",
        });
      } finally {
        this.isSaving = false;
        this.onEditState = false;
      }
    },
    async validateSectionForms() {
      try {
        let validateForms = [
          await this.$refs.patientDemographicsForm.validateAsync(),
          await this.$refs.patientHomePhone.$refs.form.validateAsync(),
          this.__Patient.phones.id || this.__Patient.phones.phone.length
            ? await this.$refs.patientCellPhone.$refs.form.validateAsync()
            : true,
          await this.$refs.patientAddress.$refs.form.validateAsync(),
        ];

        return validateForms.filter((bool) => !bool).length === 0;
      } catch (e) {
        console.log(e);
      }
    },
    async createPhone(phone) {
      const param = {
        phone: {
          type: "String!",
          value: this.sanitizePhoneString(phone.phone),
          unincludeToFields: true,
        },
        areaCode: {
          type: "String!",
          value: this.sanitizePhoneString(phone.areaCode),
          unincludeToFields: true,
        },
      };
      return await this.actions.createPhone(param);
    },
    clearAddress() {
      this.__Patient.defaultAddress.stateProvince = "";
    },
  },
  async created() {
    try {
      const genderEnum = await this.enumActions.getEnumValues("Gender");

      if (
        genderEnum &&
        genderEnum.enumValues !== null &&
        genderEnum.enumValues.length
      )
        this.genderOptions = genderEnum.enumValues.map((item) => item.name);
    } catch (err) {
      this.showNotifyMessage({
        message: `Unable to fetch data.`,
        type: "danger",
      });
    }
  },
};
