export const NAME = "TransactionDetails";

export const update_keys = Object.freeze({
  // commentted for a meantime for the 3rd phase
  // transactionDate: "updateTransactionDate",
  // payorId: "updatePayorId",
  // subscriberId: "updateSubscriberId",
  payorIcn: "updatePayorIcn",
  // cptCode: "updateCptCode",
  // total: "updateTotal",
  // deductible: "updateDeductible",
  // adjDenialCode: "updateAdjDenialCode",
  // payorPriority: "updatePayorPriority",
  // user: "updateUser",
  // paidAmounts: "updatePaidAmounts"
  // coPay: "updateCoPay",
  // coInsurance: "updateCoInsurance",
  // ptResp: "updatePtResp",
  // adjAmount: "updateAdjAmount",
  depositNumber: "updateDepositNumber",
  // checkNumber: "updateCheckNumber",
  checkAmount: "updateCheckAmount",
  // vbsCheckDate: "updateVbsCheckDate",
  // vbsPaidAmount: "updateVbsPaidAmount",
});

export const float_keys = [
  "vbsPaidAmount",
  "adjOtherPayor",
  "vbsDeductive",
  "checkAmount",
];

export const optionalAmounts = [
  "checkAmount",
  // "vbsPaidAmount",
];

export const COLUMNS = [
  {
    text: "Date",
    align: "left",
    sortable: false,
    value: "date",
    width: "150px",
  },
  {
    text: "Payor ID",
    align: "left",
    sortable: false,
    value: "code",
    width: "100px",
  },
  {
    text: "Subscriber ID",
    align: "left",
    sortable: false,
    value: "description",
    width: "100px",
  },
  {
    text: "Payor ICN",
    align: "left",
    sortable: false,
    value: "modifierCode",
    width: "100px",
  },
  {
    text: "Deposit #",
    align: "left",
    sortable: false,
    value: "price",
    width: "100px",
  },
  {
    text: "Check #",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "100px",
  },
  {
    text: "Check Date",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "150px",
  },
  {
    text: "CPT Code",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "100px",
  },
  {
    text: "Total",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "100px",
  },
  {
    text: "Deductible",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "100px",
  },
  {
    text: "CoPay",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "100px",
  },
  {
    text: "CoInsurance",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "100px",
  },
  {
    text: "PT Resp",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "100px",
  },
  {
    text: "Paid Amount",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "100px",
  },
  {
    text: "Adj Amount",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "100px",
  },
  {
    text: "Adj Denial Code",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "100px",
  },
  {
    text: "Payor Priority",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "100px",
  },
  {
    text: "User",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "100px",
  },
  {
    text: "Paid Amounts",
    align: "left",
    sortable: false,
    value: "posCode",
    width: "100px",
  },
  {
    text: "",
    align: "left",
    sortable: false,
    value: "actions",
  },
];
