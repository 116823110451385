import PayorItem from "modules/orders/Accession/components/PayorItem";
import AccessionActions from "modules//actions/accession-actions";
import DialogModal from "./modals/DialogModal";
import AddPayorModal from "modules/orders/Accession/modals/AddPayorModal";

export default {
  name: "PayorSelection",
  components: { PayorItem, DialogModal, AddPayorModal },
  props: {
    insurances: Array,
    orderId: String,
    priorAuthorization: String,
    patientInfo: Object,
    patientAddress: Object,
    changesOccured: Boolean,
  },
  data() {
    return {
      actions: new AccessionActions(),
      panels: [0, 1, 2],
      payors: [],
      panelsOpen: null,
      isSaving: false,
      onEditState: false,
      loadingMesg: "Validating fields...",
      onDrag: false,
      payorIdItems: [],
      openPayorModal: false,
      payorModal: {
        isOpen: false,
        isSearch: false,
        isLazyLoading: false,
        loadInitialData: false,
        payorIdModalLoader: true,
        searchField: "",
        totalCount: 0,
        errorOccur: false,
        selectedPayors: [],
        payorIdModalOptions: [],
        payorModalPagination: {},
        payorIdModalSearchOptions: [],
        pagination: {
          page: 5,
          itemsPerPage: 10,
          pageStop: 50,
          pageCount: 32,
        },
        payorHeaders: [
          {
            text: "Payor ID",
            align: "start",
            sortable: false,
            value: "payorId",
          },
          { text: "Payor Name", sortable: false, value: "name" },
        ],
      },
      limitData: {
        insuranceCompany: [
          "insuranceCompanyId",
          "name",
          "payorGroup",
          "payorSubGroup",
        ],
      },
      dialogModal: {
        open: false,
        title: "Delete Payor",
        body: "",
        indexToDelete: null,
      },
      savingIndexInterrupt: 0,
    };
  },
  computed: {
    selectedPayorIds() {
      return this.insurances.map((data) => data.insuranceCompany.id);
    },
    __PriorAuthorization: {
      get() {
        return this.priorAuthorization;
      },
      set(val) {
        this.$emit("update:priorAuthorization", val);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    payorIdOptions() {
      return [
        { payorId: "UKN", name: null, payorGroup: null },
        { payorId: "UHC", name: "UHC Community 1", payorGroup: "Group 1" },
        { payorId: "UHD", name: "UHD Community 3", payorGroup: "Group 3" },
        { payorId: "UHE", name: "UHE Community 2", payorGroup: "Group 2" },
        { payorId: "UHF", name: "Community UHF", payorGroup: "" },
        { payorId: "UHG", name: "Community UHG", payorGroup: "" },
      ];
    },
    hasSelectedPayors() {
      return this.payorModal.selectedPayors.length ? false : true;
    },
    itemList() {
      return this.payorModal.payorIdModalLoader
        ? []
        : this.payorModal.isSearch
        ? this.payorModal.payorIdModalSearchOptions
        : this.payorModal.payorIdModalOptions;
    },
    isAllPayorsLoaded() {
      return (
        this.payorModal.payorIdModalOptions.length == this.payorModal.totalCount
      );
    },
    unkPayorLength() {
      return this.insurances.filter((insurance) => {
        if (insurance.insuranceCompany)
          if (insurance.insuranceCompany.payorGroup == "UNK") return insurance;
      }).length;
    },
  },
  watch: {
    onDrag(val) {
      if (val) {
        this.panelsOpen = this.panels;
        this.panels = [];
      } else {
        this.panels = this.panelsOpen;
        this.panelsOpen = null;
      }
    },
    insurances(payors) {
      this.payors = payors;
    },
    payors(payors) {
      this.$emit("update:insurances", payors);
    },
    "payorModal.searchField": async function () {
      await this.searchPayorsDebounce();
    },
    "payorModal.isLazyLoading": async function () {
      if (this.isAllPayorsLoaded) return;

      await this.getPayorsDebounce();
    },
  },
  methods: {
    doClick(e, index) {
      e.preventDefault();
      e.stopPropagation();

      if (!this.insurances[index].id) {
        this.payors.splice(index, 1);
        return;
      }

      this.dialogModal.body = `Please confirm if want to delete this payor priority ${this.insurances[index].payorPriority} (${this.insurances[index].insuranceCompany.payorId}) ?`;
      this.dialogModal.indexToDelete = index;
      this.dialogModal.open = true;
    },
    async doDeletePayor() {
      this.isSaving = true;
      this.loadingMesg = `Deleting payor priority ${
        this.insurances[this.dialogModal.indexToDelete].payorPriority
      } (${
        this.insurances[this.dialogModal.indexToDelete].insuranceCompany.payorId
      })`;
      this.dialogModal.open = false;

      try {
        if (this.insurances[this.dialogModal.indexToDelete].id.length)
          await this.actions.updateAccessionDetails({
            queries: {
              deleteInsurance: {
                insuranceId: {
                  value: this.insurances[this.dialogModal.indexToDelete].id,
                  type: "UUID!",
                },
              },
            },
            orderId: {
              value: this.orderId,
              type: "UUID!",
              unincludeToFields: true,
            },
          });

        const deletedPayor =
          this.payors[this.dialogModal.indexToDelete].insuranceCompany.payorId;
        this.payors.splice(this.dialogModal.indexToDelete, 1);

        this.loadingMesg = `Updating payors`;
        for (let i = 0; i < this.insurances.length; i++) {
          let priority = i + 1;
          await this.actions.updateInsurance({
            queries: {
              updatePayorPriority: {
                payorPriority: {
                  value: priority,
                  type: "Int!",
                },
              },
            },
            params: {
              insuranceId: {
                value: this.insurances[i].id,
                type: "UUID!",
                unincludeToFields: true,
              },
            },
          });
          this.insurances[i].payorPriority = priority;
        }
        this.$emit("updateAccession");
        this.isSaving = false;
        this.showNotifyMessage({
          message: `The payor '${deletedPayor}' has deleted`,
          type: "success",
        });
      } catch (e) {
        this.isSaving = false;
        this.showNotifyMessage({
          message: `Problem has occurred while deleting data.`,
          type: "danger",
        });
      }
    },
    onDrop() {
      for (let i = 0; i < this.insurances.length; i++)
        this.insurances[i].payorPriority = i + 1;

      this.onDrag = false;
    },
    onHold() {
      window.scroll({
        left: 0,
        top: this.$refs.container.offsetParent.offsetTop + 100,
        behavior: "smooth",
      });
      this.onDrag = true;
    },
    stopPropagation(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    async openPayorIdSelection() {
      let payors = this.payorIdItems;
      this.payorIdItems = [{ text: "Loading", disabled: true }];
      setTimeout(() => {
        this.payorIdItems = payors;
      }, 1000);
    },
    selectedChange(value, index) {
      this.payors[index].payorId = value.payorId;
      this.payors[index].payorName = value.payorName;
      if (value.payorId == "UKN") {
        const keys = Object.keys(this.payors[index]);
        for (let i = 0; i < keys.length; i++) {
          if (
            keys[i] == "priority" ||
            keys[i] == "payorId" ||
            keys[i] == "payorName" ||
            keys[i] == "selectedPayor"
          )
            continue;
          this.payors[index][keys[i]] = null;
        }
      }
    },
    async addSelected(selectedPayors) {
      let { insurances } = this;
      let currentCount = insurances.length;

      for (let i = 0; i < selectedPayors.length; i++) {
        this.panels.push(currentCount);
        this.$emit("addPayor", {
          payorPriority: ++currentCount,
          insuranceCompany: selectedPayors[i],
        });
      }
    },
    parseInsuranceCompanyResponseData(companies) {
      return companies.map((company) => {
        return {
          id: company.id,
          payorId: company.insuranceCompanyId,
          name: company.name,
          payorGroup: company.payorGroup,
          payorSubGroup: company.payorSubGroup,
        };
      });
    },
    async getPayors() {
      // api call here
      // and haddle of response
      setTimeout(async () => {
        try {
          if (this.payorModal.isOpen && !this.payorModal.loadInitialData) {
            this.payorModal.payorIdModalLoader = true;
            const insuranceCompanies =
              await this.actions.getInsuranceCompanyList({
                limit: 20,
                limitData: [...this.limitData.insuranceCompany, "totalCount"],
              });
            this.payorModal.loadInitialData = true;
            this.payorModal.payorIdModalOptions =
              this.parseInsuranceCompanyResponseData(insuranceCompanies);
            this.payorModal.totalCount = insuranceCompanies[0].totalCount;
          } else {
            {
              if (this.payorModal.isLazyLoading) {
                const insuranceCompanies =
                  await this.actions.getInsuranceCompanyList({
                    limit: 10,
                    limitData: this.limitData.insuranceCompany,
                    offset: this.payorModal.payorIdModalOptions.length,
                  });
                this.payorModal.payorIdModalOptions =
                  this.payorModal.payorIdModalOptions.concat(
                    this.parseInsuranceCompanyResponseData(insuranceCompanies)
                  );
              }
              this.payorModal.isLazyLoading = false;
            }
          }
          this.payorModal.payorIdModalLoader = false;
        } catch (e) {
          this.payorModal.errorOccur = true;
        }
      }, 500);
    },
    async searchPayors() {
      setTimeout(async () => {
        try {
          this.payorModal.payorIdModalLoader = true;
          if (
            this.payorModal.searchField &&
            this.payorModal.searchField.length > 2
          ) {
            this.payorModal.isSearch = true;
            const insuranceCompanies =
              await this.actions.getInsuranceCompanyList(
                {
                  filter: {
                    name: this.payorModal.searchField,
                    insuranceCompanyId: this.payorModal.searchField,
                  },
                  limitData: this.limitData.insuranceCompany,
                },
                "to seacrh payors"
              );
            this.payorModal.payorIdModalSearchOptions =
              this.parseInsuranceCompanyResponseData(insuranceCompanies);
          } else {
            this.payorModal.payorIdModalSearchOptions = [];
            this.payorModal.isSearch = false;
          }

          this.payorModal.payorIdModalLoader = false;
        } catch (e) {
          this.payorModal.errorOccur = true;
        }
      }, 500);
    },
    async refetchPayors() {
      await this.getPayorsDebounce();
      this.payorModal.errorOccur = false;
    },
    async validateForm() {
      const payorItems = this.$refs.payorItem;
      const payors = this.insurances;
      let clearOfValidation = true;
      let currentIndex = 0;

      var arrayIndex = this.savingIndexInterrupt || 0;
      for (let payorItem of payorItems) {
        const payorItemValidated = await payorItem.validatePayorItem();
        clearOfValidation = clearOfValidation && payorItemValidated;
      }

      if (!clearOfValidation) {
        this.showNotifyMessage({
          message: `Kindly fill all the required fields`,
          type: "danger",
        });
        return;
      }

      this.isSaving = true;
      var index;
      index = arrayIndex;
      var order = {
        queries: {
          setPriorAuthorization: {
            priorAuthorization: {
              value: this.priorAuthorization == "yes" ? true : false,
              type: "Boolean",
            },
          },
        },
      };

      try {
        for (index = arrayIndex; index < payors.length; index++) {
          currentIndex = index;

          if (payors[index].id)
            this.loadingMesg = `Updating payor priority ${payors[index].payorPriority} (${payors[index].insuranceCompany.payorId})`;
          else
            this.loadingMesg = `Saving payor priority ${payors[index].payorPriority} (${payors[index].insuranceCompany.payorId})`;

          const {
            queries,
            childQueries,
            addressExisting,
            insuredExisting,
            createAddress,
            createInsured,
          } = this.fieldsToUpdate(payors[index]);

          if (
            !addressExisting &&
            Object.keys(createAddress.params).length != 0
          ) {
            const createdAddress = await this.actions.createAddress({
              params: {
                ...createAddress.params,
              },
            });
            this.insurances[index].insured.defaultAddress.id =
              createdAddress.id;
          }

          if (
            !insuredExisting &&
            Object.keys(createInsured.params).length != 0
          ) {
            let params = {
              params: {
                ...createInsured.params,
              },
            };

            if (this.insurances[index].insured.defaultAddress.id)
              params["queries"] = {
                addAddress: {
                  addressId: {
                    value: this.insurances[index].insured.defaultAddress.id,
                    type: "UUID!",
                  },
                },
                setDefaultAddress: {
                  addressId: {
                    value: this.insurances[index].insured.defaultAddress.id,
                    type: "UUID!",
                  },
                },
              };
            const createdInsured = await this.actions.createInsured(params);

            this.insurances[index].insured.id = createdInsured.id;

            queries["setInsured"] = {
              insuredId: {
                value: createdInsured.id,
                type: "UUID!",
              },
            };
          }

          if (
            !addressExisting &&
            insuredExisting &&
            this.insurances[index].insured.defaultAddress.id &&
            this.insurances[index].insured.id
          ) {
            childQueries[0].insured["addAddress"] = {
              addressId: {
                value: this.insurances[index].insured.defaultAddress.id,
                type: "UUID!",
              },
            };
            childQueries[0].insured["setDefaultAddress"] = {
              addressId: {
                value: this.insurances[index].insured.defaultAddress.id,
                type: "UUID!",
              },
            };
          }

          if (this.insurances[index].id && this.insurances[index].id.length) {
            const result = await this.actions.updateInsurance({
              queries,
              childQueries,
              params: {
                insuranceId: {
                  value: this.insurances[index].id,
                  type: "UUID!",
                  unincludeToFields: true,
                },
              },
            });
            if (result.addNote) this.updateNoteId(result.id, index);
          } else {
            if (!order.collectionQueries) order["collectionQueries"] = [];
            const createdInsurance = await this.actions.createInsurance({
              queries,
              params: {
                insuranceMemberId: {
                  value: this.insurances[index].groupId,
                  type: "String!",
                  unincludeToFields: true,
                },
              },
            });

            this.insurances[index].id = createdInsurance.id;
            order["collectionQueries"].push({
              addInsurance: {
                insuranceId: { value: createdInsurance.id, type: "UUID!" },
              },
            });

            if (createdInsurance.addNote)
              this.updateNoteId(createdInsurance.id, index);
          }
        }

        await this.actions.updateAccessionDetails({
          queries: order.queries,
          collectionQueries: order.collectionQueries || [],
          orderId: {
            value: this.orderId,
            type: "UUID!",
            unincludeToFields: true,
          },
        });

        this.isSaving = false;
        this.savingIndexInterrupt = null;
        this.$emit("updateAccession");
        this.showNotifyMessage({
          message: `Saved and updated all payor(s).`,
          type: "success",
        });
      } catch (e) {
        this.savingIndexInterrupt = currentIndex;
        this.showNotifyMessage({
          message: `Problem has occurred while saving data.`,
          type: "danger",
        });
        this.isSaving = false;
      }
    },
    async updateNoteId(insuranceId, index) {
      const insurance = await this.actions.getInsuranceById(insuranceId, [
        "notes{ id }",
      ]);
      this.insurances[index].note.id = insurance.notes[0].id;
    },
    fieldsToUpdate(payor) {
      const payorKeys = Object.keys(payor);

      let query = {
        queries: {},
        childQueries: [],
        addressExisting: true,
        insuredExisting: true,
        createAddress: { params: {} },
        createInsured: { params: {} },
      };
      for (let i = 0; i < payorKeys.length; i++) {
        if (
          payorKeys[i].toUpperCase() == "ID" ||
          payorKeys[i].toUpperCase() == "AUTHORIZATIONREQUIRED"
        )
          continue;

        if (payorKeys[i].toUpperCase() == "INSURED") {
          let insuredQuery = {};
          let nameUpdates = {};
          let addressUpdates = {};
          const insured = payor[payorKeys[i]];
          const address = payor[payorKeys[i]].defaultAddress;
          const addressKeys = Object.keys(payor[payorKeys[i]].defaultAddress);
          const insuredKeys = Object.keys(payor[payorKeys[i]]);

          for (let x = 0; x < insuredKeys.length; x++) {
            if (insured.id) {
              if (
                ["firstName", "lastName", "middleName"].includes(insuredKeys[x])
              ) {
                nameUpdates[insuredKeys[x]] = {
                  value: insured[insuredKeys[x]],
                  type: "String",
                };
              }
            } else {
              if (
                ["firstName", "lastName", "middleName"].includes(insuredKeys[x])
              ) {
                if (insured[insuredKeys[x]].length)
                  query.createInsured.params[insuredKeys[x]] = {
                    value: insured[insuredKeys[x]],
                    type: "String",
                  };
              }
              query.insuredExisting = false;
            }
          }

          for (let x = 0; x < addressKeys.length; x++) {
            if (address.id) {
              if (
                [
                  "id",
                  "city",
                  "stateProvince",
                  "postalCode",
                  "line1",
                  "country",
                ].includes(addressKeys[x])
              ) {
                addressUpdates[
                  addressKeys[x] == "id" ? "addressId" : addressKeys[x]
                ] = {
                  value: address[addressKeys[x]],
                  type: addressKeys[x] == "id" ? "UUID!" : "String",
                };
              }
            } else {
              if (
                [
                  "city",
                  "stateProvince",
                  "postalCode",
                  "line1",
                  "country",
                ].includes(addressKeys[x])
              ) {
                if (address[addressKeys[x]].length)
                  query.createAddress.params[addressKeys[x]] = {
                    value: address[addressKeys[x]],
                    type:
                      addressKeys[x].toUpperCase() == "LINE1"
                        ? "String!"
                        : "String",
                  };
              }
              query.addressExisting = false;
            }
          }

          if (Object.keys(nameUpdates).length)
            insuredQuery["updateName"] = nameUpdates;

          if (Object.keys(addressUpdates).length)
            insuredQuery["updateAddress"] = addressUpdates;

          if (Object.keys(insuredQuery).length)
            query.childQueries.push({ insured: insuredQuery });
        } else if (
          [
            "insuranceRelationshipType",
            "msp",
            "insuranceCompany",
            "note",
          ].includes(payorKeys[i])
        ) {
          if (payorKeys[i].toUpperCase() == "NOTE") {
            if (payor[payorKeys[i]].id) {
              let noteId = this.setParam(
                payor[payorKeys[i]].id,
                payorKeys[i],
                "UUID!"
              );
              let note = this.setParam(
                payor[payorKeys[i]].note,
                payorKeys[i],
                "String!"
              );
              query.queries[
                `update${this.capitalizeFirstLetter(payorKeys[i])}`
              ] = { ...noteId, ...note };
            } else if (payor[payorKeys[i]].note.length)
              query.queries[`add${this.capitalizeFirstLetter(payorKeys[i])}`] =
                this.setParam(
                  payor[payorKeys[i]].note,
                  payorKeys[i],
                  "String!"
                );
          } else if (payor[payorKeys[i]])
            query.queries[`set${this.capitalizeFirstLetter(payorKeys[i])}`] =
              this.setParam(payor[payorKeys[i]].id, payorKeys[i], "UUID!");
        } else if (typeof payor[payorKeys[i]] == "string") {
          if (payorKeys[i].toUpperCase() == "SUBSCRIBERID")
            query.queries[`update${this.capitalizeFirstLetter(payorKeys[i])}`] =
              this.setParam(payor[payorKeys[i]], payorKeys[i], "String!");
          else
            query.queries[`update${this.capitalizeFirstLetter(payorKeys[i])}`] =
              this.setParam(payor[payorKeys[i]], payorKeys[i], "String");
        } else if (typeof payor[payorKeys[i]] == "number")
          query.queries[`update${this.capitalizeFirstLetter(payorKeys[i])}`] =
            this.setParam(payor[payorKeys[i]], payorKeys[i], "Int!");
      }
      return query;
    },
    setParam(value, queryKey, type) {
      let param = {};
      param[`${type == "UUID!" ? queryKey + "Id" : queryKey}`] = {
        value,
        type,
      };
      return param;
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    emitCheckChanges() {
      this.$emit("checkChanges", { section: "payorIdSelection" });
    },
    setPatientInfo({ toClear = false, index = 0}) {
      const nameFields = ["firstName", "lastName", "middleName"];
      const addressFields = [
        "line1",
        "city",
        "postalCode",
        "stateProvince",
        "country",
      ];
      nameFields.forEach(nameField => {
        if (this.patientInfo[nameField]) {
          this.insurances[index].insured[nameField] = toClear
            ? ""
            : this.patientInfo[nameField];
        }
      });
      const address = {};
      addressFields.forEach(addressField => {
        if (this.patientAddress[addressField])
          address[addressField] = toClear ? "" : this.patientAddress[addressField];
      });
      this.insurances[index].insured.defaultAddress.country = address.country;
      this.$nextTick(() => {
        this.insurances[index].insured.defaultAddress = { ...address };
      });
    },
    onCancel() {
      this.onEditState = false;
      this.$emit("cancelChanges", { section: "payorIdSelection" });
    },
  },
  async mounted() {
    this.payors = this.insurances;
  },
  async created() {
    this.getPayorsDebounce = await this.debounce(
      async () => await this.getPayors()
    );
    this.searchPayorsDebounce = await this.debounce(
      async () => await this.searchPayors()
    );
    this.panels = Array.from(Array(this.insurances.length).keys());
  },
};
