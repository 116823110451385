import { uuid } from "vue-uuid";

import AddDiagnosisModal from "modules/orders/Accession/modals/AddDiagnosisModal";
import AccessionActions from "modules/actions/accession-actions";

export default {
  name: "DiagnosisCodesSection",
  components: {
    AddDiagnosisModal,
  },
  props: {
    diagnosisCodesCopy: {
      required: true,
      type: Array,
    },
    diagnosisCodes: {
      required: true,
      type: Array,
    },
    orderId: {
      type: String,
      required: true,
    },
    dateOfService: {
      type: String,
      required: true,
    },
  },
  computed: {
    __DiagnosisCodes: {
      get() {
        return this.diagnosisCodes;
      },
      set(value) {
        return this.$emit("update:diagnosisCodes", value);
      },
    },
    excludedOnSearch() {
      return this.__DiagnosisCodes.length
        ? this.__DiagnosisCodes.map((item) => item.diagnosticCode.id)
        : [];
    },
    dataTable: function () {
      return this.showInactive ? this.inactiveDxCode : this.activeDxCode;
    },
    shouldDisableAction() {
      return this.isSavingCodes || !this.onEditState;
    },
  },
  data() {
    return {
      actions: new AccessionActions(),
      filterBy: "none",
      showInactive: false,
      isSavingCodes: false,
      onEditState: false,
      refresher: 0,
      inactiveDxCode: this.byStatus(this.diagnosisCodes, true),
      activeDxCode: this.byStatus(this.diagnosisCodes),
      headers: [
        {
          text: "Order",
          value: "order",
          sortable: false,
        },
        {
          text: "Diagnosis Code",
          value: "code",
          sortable: false,
        },
        {
          text: "Diagnosis Code Description",
          value: "description",
          sortable: false,
        },
        {
          text: "User ID",
          value: "userId",
          sortable: false,
        },
        {
          text: "Date",
          value: "dateTime",
          sortable: false,
        },
        {
          text: "Notes",
          value: "notes",
          sortable: false,
        },
        {
          text: "Inactive",
          value: "actions",
          sortable: false,
        },
        {
          text: "",
          value: "sort",
        },
        {
          text: "",
          value: "delete",
        },
      ],
      addDiagnosisModalVisibility: false,
      isManuallyAdded: false,
    };
  },
  methods: {
    byStatus(dxCode, inactive = false) {
      return (
        dxCode.filter((code) => (inactive ? code.active : !code.active)) || []
      );
    },
    async saveAddedCode() {
      try {
        this.isSavingCodes = true;
        const billingDiagnosticCodeCopy = this.__DiagnosisCodes;

        let params = {
          orderId: {
            type: "UUID!",
            value: this.orderId,
            unincludeToFields: true,
          },
          queries: {
            setOrderEntrySaved: {
              orderEntrySaved: {
                type: "Boolean!",
                value: true,
              },
            },
          },
          childQueries: [],
          collectionQueries: [],
        };

        let toAddBillingDiagnosticCode = [];

        for (var i = 0; i < billingDiagnosticCodeCopy.length; i++) {
          if (billingDiagnosticCodeCopy[i].id === null) {
            let bdcParams = {
              addBillingDiagnosticCode: {
                diagnosticCodeId: {
                  type: "UUID!",
                  value: billingDiagnosticCodeCopy[i].diagnosticCode.id,
                },
                addedBy: {
                  type: "UUID!",
                  value: billingDiagnosticCodeCopy[i].addedBy.id,
                },
                addedDate: {
                  type: "String!",
                  value: this.$options.filters.getPSTDate(
                    Date.now(),
                    "MM/DD/YYYY"
                  ),
                },
                active: {
                  type: "Boolean",
                  value: !billingDiagnosticCodeCopy[i].active,
                },
                sortOrder: {
                  type: "Int",
                  value: i + 1,
                },
              },
            };

            if (
              billingDiagnosticCodeCopy[i].notes !== null &&
              billingDiagnosticCodeCopy[i].notes.length
            )
              bdcParams.addBillingDiagnosticCode["notes"] = {
                type: "String",
                value: billingDiagnosticCodeCopy[i].notes,
              };

            const res = await this.actions.updateAccessionDetails({
              orderId: {
                type: "UUID!",
                value: this.orderId,
                unincludeToFields: true,
              },
              queries: { ...bdcParams },
            });

            billingDiagnosticCodeCopy[i].id = res.id;
          } else {
            await this.actions.updateOBDCDetails({
              orderBillingDiagnosticCodeId: {
                type: "UUID!",
                value: billingDiagnosticCodeCopy[i].id,
                unincludeToFields: true,
              },
              queries: {
                updateActive: {
                  active: {
                    type: "Boolean!",
                    value: !billingDiagnosticCodeCopy[i].active,
                  },
                },
              },
            });
            await this.actions.updateAccessionDetails({
              orderId: {
                type: "UUID!",
                value: this.orderId,
                unincludeToFields: true,
              },
              queries: {
                updateBillingDiagnosticCodeSortOrder: {
                  diagnosticCodeId: {
                    type: "UUID!",
                    value: billingDiagnosticCodeCopy[i].id,
                    unincludeToFields: true,
                  },
                  sortOrder: {
                    type: "Int",
                    value: i + 1,
                    unincludeToFields: true,
                  },
                },
              },
            });
          }
        }

        params.collectionQueries = params.collectionQueries.length
          ? params.collectionQueries.concat(...toAddBillingDiagnosticCode)
          : toAddBillingDiagnosticCode;
        if (
          Object.keys(params.queries).length > 0 ||
          params.collectionQueries.length
        ) {
          await this.actions.updateAccessionDetails(params);
        }

        const result = await this.actions.getOrderEntryById({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value:  this.orderId,
              },
              ["orderBillingErrorCodes.orderByDate"]: {
                type: "DateOrderSort",
                value: {
                  dateField: "CREATED",
                  orderBy: "DESC",
                }
              }
            },
          },
          limitData: [
            this.buildSubQuery("billingDiagnosticCode", [
              this.buildSubQuery("diagnosticCode", ["code", "description"]),
              this.buildSubQuery("addedBy", ["email"]),
              "addedDate",
              "notes",
              "active",
            ]),
          ],
        });

        if (
          result &&
          result.billingDiagnosticCode &&
          result.billingDiagnosticCode.length
        )
          this.__DiagnosisCodes = result.billingDiagnosticCode.map((item) => {
            const { diagnosticCode, addedBy } = item;

            return {
              id: item.id,
              diagnosticCode: diagnosticCode
                ? {
                    id: diagnosticCode.id,
                    code: diagnosticCode.code,
                    description: diagnosticCode.description,
                  }
                : {
                    id: null,
                    code: "",
                    description: "",
                  },
              addedBy: addedBy
                ? {
                    id: addedBy.id,
                    email: addedBy.email,
                  }
                : {
                    id: null,
                    email: "",
                  },
              addedDate: item.addedDate,
              notes: item.notes,
              active: !item.active,
            };
          });

        this.$emit("update:diagnosisCodesCopy", billingDiagnosticCodeCopy);

        this.showNotifyMessage({
          message: `Diagnosis codes saved.`,
          type: "success",
        });
      } catch (err) {
        this.showNotifyMessage({
          message: `Problem has occurred while saving data.`,
          type: "danger",
        });
      } finally {
        this.onEditState = false;
        this.isSavingCodes = false;
      }
    },
    removeCode(uuid) {
      const findIndex = this[
        this.showInactive ? "inactiveDxCode" : "activeDxCode"
      ].findIndex((item) => item.uuid && item.uuid === uuid);
      const findIndex_ = this.__DiagnosisCodes.findIndex(
        (item) => item.uuid && item.uuid === uuid
      );

      if (findIndex > -1) {
        this.showInactive
          ? this.inactiveDxCode.splice(findIndex, 1)
          : this.activeDxCode.splice(findIndex, 1);
        this.__DiagnosisCodes.splice(findIndex_, 1);
      }
    },
    showAddDiagnosisModal() {
      this.addDiagnosisModalVisibility = true;
    },
    addDiagnosisCode(e) {
      try {
        const { addDiagnosisCode, diagnosticCode, addedBy } = e;

        if (addDiagnosisCode) {
          const diagnosisCodeAlreadyExists =
            this.diagnosisCodes.filter(
              (bdc) => bdc.diagnosticCode.id === diagnosticCode.id
            ).length > 0;

          if (!diagnosisCodeAlreadyExists) {
            this.activeDxCode.push({
              id: null,
              uuid: uuid.v1(),
              diagnosticCode: {
                id: diagnosticCode.id,
                code: diagnosticCode.code,
                description: diagnosticCode.description,
              },
              addedBy: addedBy,
              addedDate: "",
              notes: diagnosticCode.notes,
              active: false,
            });
          } else {
            this.showNotifyMessage({
              message: "Diagnostic code already exists.",
              type: "danger",
            });
          }
        }
        this.isManuallyAdded = true;

        this.addDiagnosisModalVisibility = false;
      } catch (err) {
        this.showNotifyMessage({
          message: `Problem has occurred while saving data.`,
          type: "danger",
        });
      } finally {
        this.$emit(
          "update:diagnosisCodes",
          this.activeDxCode.concat(this.inactiveDxCode)
        );
      }
    },
    isCurrentBillingCode(item) {
      return item.id !== null;
    },
    isRemovable(item) {
      return !item.id;
    },
    showInactiveItems(e) {
      if (e) {
        this.filterBy = "inactive";
      } else this.filterBy = "none";
    },
    filterItems(value, search, item) {
      return search.toUpperCase() === "INACTIVE" ? item.active : !item.active;
    },
    changeOrderPriority(diag, type) {
      let itemIndex;
      let newIndex;

      if (diag.uuid) {
        itemIndex = this.dataTable.findIndex((item) => item.uuid === diag.uuid);
      } else {
        itemIndex = this.dataTable.findIndex((item) => item.id === diag.id);
      }

      if (itemIndex !== undefined && itemIndex > -1) {
        const newValue = this.dataTable[itemIndex];

        newIndex = itemIndex + (type.toUpperCase() === "ASC" ? -1 : 1);

        // remove from collection
        this.dataTable.splice(itemIndex, 1);

        // reinsert from collection
        this.dataTable.splice(newIndex, 0, newValue);

        this.$emit(
          "update:diagnosisCodes",
          this.activeDxCode.concat(this.inactiveDxCode)
        );
      }
    },
    updateStatus(data) {
      const to = data.active ? this.inactiveDxCode : this.activeDxCode;
      const from = !data.active ? this.inactiveDxCode : this.activeDxCode;
      const index = from.findIndex((code) => code.id == data.id);
      to.push(data);
      from.splice(index, 1)[0];
      this.refresher += 1;
      this.$emit(
        "update:diagnosisCodes",
        this.activeDxCode.concat(this.inactiveDxCode)
      );
    },
    isLastIndex(index, hayStack) {
      return index + 1 === hayStack.length;
    },
    isFirstIndex(index) {
      return index === 0;
    },
    onCancel() {
      this.$emit("update:diagnosisCodes", this.diagnosisCodesCopy);

      this.onEditState = false;
    },
  },
};
