import AccessionActions from "modules/actions/accession-actions";

export default {
  name: "PayorItem",
  components: {},
  props: {
    isEdit: Boolean,
    selected: Array,
    payorName: String,
    payorGroup: String,
    msp: { type: [Object, String, null] },
    priority: { type: [String, Number], required: true },
    payor: { type: [Object, String, null] },
    unkPayorLength: { type: Number, required: true, value: 0 },
    claimNote: { type: String, required: true },
    planId: { type: String, required: true },
    planName: { type: String, required: true },
    subscriberId: { type: String, required: true },
    authorizationNumber: { type: String, required: true },
    authorizationRequired: { type: String, required: true },
    groupId: { type: String, required: true },
    groupName: { type: String, required: true },
    insuredRelationship: { type: [Object, String, null] },
    insuredAddress: { type: Object, required: true },
    insuredLastname: { type: String, required: true },
    insuredFirstname: { type: String, required: true },
    insuredMiddlename: { type: String, required: true },
  },
  data() {
    return {
      actions: new AccessionActions(),
      onDrag: false,
      getIntialData: false,
      comment: "213",
      refresher: 0,
      insuredRelationshipItems: ["Self", "Spouse", "Child", "Other / Unknown"],
      authorizationOptions: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      rules: {
        required: [val => !["", null, undefined].includes(val) || "This field is required."],
        validateInsured: [
          (val) => {
            if (this.__Relationship && this.__Relationship.id) {
              return (
                (val !== null && val.length !== 0) || "This field is required."
              );
            }

            return true;
          },
          (val) =>
            /^[a-zA-Z0-9 ]*$/.test(val) || "Should not have special characters",
        ],
        validateHasCountry: [
          (val) => {
            if (this.__Address.id) {
              return (
                (val !== null && val.length > 0) || "This field is required."
              );
            }

            return true;
          },
        ],
        validateViaCountry: [
          (val) => {
            if (
              this.__Address.id ||
              (this.__Address.country && this.__Address.country.length)
            ) {
              return (
                (val !== null && val.length > 0) || "This field is required."
              );
            }

            return true;
          },
        ],
      },
      payorSelect: {
        items: [{ text: "Loading", value: {}, disabled: true }],
        isLoading: false,
        isLazyLoading: false,
        errorOccur: false,
        totalCount: 0,
        loadInitialData: false,
      },
      mspSelect: {
        items: [{ text: "Loading", value: {}, disabled: true }],
        isLoading: false,
        isLazyLoading: false,
        errorOccur: false,
        totalCount: 0,
        loadInitialData: false,
      },
      insuranceRelationshipSelect: {
        items: [{ text: "Loading", value: {}, disabled: true }],
        isLoading: false,
        isLazyLoading: false,
        errorOccur: false,
        totalCount: 0,
        loadInitialData: false,
      },
      limitData: {
        insuranceCompany: [
          "insuranceCompanyId",
          "name",
          "payorGroup",
          "payorSubGroup",
        ],
        msp: ["mspCode", "mspCodeDescription"],
        relationshipType: ["hippaCode", "label"],
      },
      address: {
        country: "",
        stateProvince: "",
      },
    };
  },
  computed: {
    isPayorUkn() {
      return this.payor ? this.payor.payorGroup.toUpperCase() == "UNK" : false;
    },
    payorOptions() {
      if (this.payor) {
        if (!this.payorSelect.loadInitialData && this.payorSelect.isLoading)
          return this.payorSelect.items.concat([
            { text: this.payor.payorId, value: this.payor },
          ]);
        if (
          this.payorSelect.loadInitialData &&
          this.payorSelect.items.findIndex(
            (item) => item.value.id == this.payor.id
          ) == -1
        )
          return [
            ...this.payorSelect.items,
            { text: this.payor.payorId, value: this.payor },
          ];
        else if (this.payorSelect.loadInitialData)
          return this.payorSelect.items;

        return [
          ...this.payorSelect.items,
          { text: this.payor.payorId, value: this.payor },
        ];
      } else return this.payorSelect.items;
    },
    relationshipTypeOptions() {
      if (this.insuredRelationship) {
        if (
          !this.insuranceRelationshipSelect.loadInitialData &&
          this.insuranceRelationshipSelect.isLoading
        )
          return this.insuranceRelationshipSelect.items.concat([
            {
              text: this.insuredRelationship.label,
              value: this.insuredRelationship,
            },
          ]);
        else if (
          this.insuranceRelationshipSelect.items.findIndex(
            (item) => item.value.id == this.insuredRelationship.id
          ) == -1 &&
          this.insuranceRelationshipSelect.loadInitialData
        )
          return [
            ...this.insuranceRelationshipSelect.items,
            {
              text: this.insuredRelationship.label,
              value: this.insuredRelationship,
            },
          ];
        return [
          ...this.insuranceRelationshipSelect.items,
          {
            text: this.insuredRelationship.label,
            value: this.insuredRelationship,
          },
        ];
      } else return this.insuranceRelationshipSelect.items;
    },
    mspOptions() {
      if (this.msp) {
        if (!this.mspSelect.loadInitialData)
          return this.mspSelect.items.concat([
            {
              text: `${this.msp.mspCode} - ${this.msp.mspCodeDescription}`,
              value: this.msp,
            },
          ]);
        else if (
          this.mspSelect.items.findIndex(
            (item) => item.value.id == this.msp.id
          ) == -1 &&
          this.mspSelect.loadInitialData
        )
          return [
            ...this.mspSelect.items,
            {
              text: `${this.msp.mspCode} - ${this.msp.mspCodeDescription}`,
              value: this.msp,
            },
          ];
        return this.mspSelect.items;
      } else return this.mspSelect.items;
    },
    isAllPayorsLoaded() {
      return this.payorSelect.items.length == this.payorSelect.totalCount;
    },
    isAllMSPsLoaded() {
      return this.mspSelect.items.length == this.mspSelect.totalCount;
    },
    isAllRelationshipTypesLoaded() {
      return (
        this.insuranceRelationshipSelect.items.length ==
        this.insuranceRelationshipSelect.totalCount
      );
    },
    isRelationshipSelf() {
      if (this.insuredRelationship?.id) {
        return this.insuredRelationship?.label.toUpperCase() === "SELF";
      }
      return false;
    },
    isRelationshipEmpty() {
      return this.insuredRelationship === null || this.insuredRelationship.id === null;
    },
    __Priority: {
      get() {
        return this.priority;
      },
      set(value) {
        this.$emit("update:priority", value);
      },
    },
    __Payor: {
      get() {
        return this.payor;
      },
      set(value) {
        if (this.selected.includes(value.id) && this.payor.id !== value.id) {
          this.showNotifyMessage({
            message: "Payor ID already exists.",
            type: "danger",
          });
          this.refresher += 1;
        } else this.$emit("update:payor", value);
      },
    },
    __PayorName: {
      get() {
        return this.payorName;
      },
      set(value) {
        this.$emit("update:payorName", value);
      },
    },
    __MSP: {
      get() {
        return this.msp;
      },
      set(value) {
        this.$emit("update:msp", value);
      },
    },
    __AuthorizationNumber: {
      get() {
        return this.authorizationNumber;
      },
      set(value) {
        this.$emit("update:authorizationNumber", value);
      },
    },
    __AuthorizationRequired: {
      get() {
        return this.authorizationRequired;
      },
      set(value) {
        this.$emit("update:authorizationRequired", value || "");
      },
    },
    __GroupId: {
      get() {
        return this.groupId;
      },
      set(value) {
        this.$emit("update:groupId", value);
      },
    },
    __GroupName: {
      get() {
        return this.groupName;
      },
      set(value) {
        this.$emit("update:groupName", value);
      },
    },
    __Relationship: {
      get() {
        return this.insuredRelationship;
      },
      set(value) {
        this.$emit("update:insuredRelationship", value);
      },
    },
    __Firstname: {
      get() {
        return this.insuredFirstname;
      },
      set(value) {
        this.$emit("update:insuredFirstname", value);
      },
    },
    __Lastname: {
      get() {
        return this.insuredLastname;
      },
      set(value) {
        this.$emit("update:insuredLastname", value);
      },
    },
    __Middlename: {
      get() {
        return this.insuredMiddlename;
      },
      set(value) {
        this.$emit("update:insuredMiddlename", value);
      },
    },
    __PlanId: {
      get() {
        return this.planId;
      },
      set(value) {
        this.$emit("update:planId", value);
      },
    },
    __PlanName: {
      get() {
        return this.planName;
      },
      set(value) {
        this.$emit("update:planName", value);
      },
    },
    __SubscriberId: {
      get() {
        return this.subscriberId;
      },
      set(value) {
        this.$emit("update:subscriberId", value);
      },
    },
    __Address: {
      get() {
        return this.insuredAddress;
      },
      set(value) {
        this.$emit("update:insuredAddress", value);
      },
    },
    __PayorGroupName: {
      get() {
        return this.payorGroup;
      },
      set(value) {
        this.$emit("update:payorGroup", value);
      },
    },
    __ClaimNote: {
      get() {
        return this.claimNote;
      },
      set(value) {
        this.$emit("update:claimNote", value);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    payorIdOptions() {
      return [
        { payorId: "UNK", name: null, payorGroup: null },
        { payorId: "UHC", name: "UHC Community 1", payorGroup: "Group 1" },
        { payorId: "UHD", name: "UHD Community 3", payorGroup: "Group 3" },
        { payorId: "UHE", name: "UHE Community 2", payorGroup: "Group 2" },
        { payorId: "UHF", name: "Community UHF", payorGroup: "" },
        { payorId: "UHG", name: "Community UHG", payorGroup: "" },
      ];
    },
  },
  watch: {
    "payorSelect.isLazyLoading": async function () {
      if (this.isAllPayorsLoaded) return;

      await this.getPayorsDebounce();
    },
    "mspSelect.isLazyLoading": async function () {
      if (this.isAllMSPsLoaded) return;

      await this.getMSPsDebounce();
    },
    __Relationship(newVal, oldVal) {
      if (newVal.label.toUpperCase() === "SELF") {
        this.$emit("setPatient", { toClear: false, index: this.priority - 1 });
      } else if (oldVal?.label.toUpperCase() === "SELF") {
        this.$emit("setPatient", { toClear: true, index: this.priority - 1 });
        this.$refs.form.resetValidation();
      }
    },
  },
  methods: {
    parseInsuranceCompanyResponseData(companies) {
      return companies.map((company) => {
        return {
          text: company.insuranceCompanyId,
          value: {
            id: company.id,
            payorId: company.insuranceCompanyId,
            name: company.name,
            payorGroup: company.payorGroup,
            payorSubGroup: company.payorSubGroup,
          },
          disabled:
            this.unkPayorLength == 5
              ? this.payor.payorGroup != "UNK" && company.payorGroup == "UNK"
              : false,
        };
      });
    },
    parseMspResponseData(msps) {
      return msps.map((msp) => {
        return {
          text: `${msp.mspCode} - ${msp.mspCodeDescription}`,
          value: {
            id: msp.id,
            mspCode: msp.mspCode,
            mspCodeDescription: msp.mspCodeDescription,
          },
        };
      });
    },
    parseRelationsipResponseData(types) {
      let unkownIndex = types.findIndex(
        (type) => type.label.toUpperCase() == "UNKNOWN"
      );
      types.push(types.splice(unkownIndex, 1)[0]);
      return types.map((type) => {
        return {
          text: `${type.label}`,
          value: {
            id: type.id,
            label: type.label,
            hippaCode: type.hippaCode,
          },
        };
      });
    },
    async getPayors() {
      // api call here
      // and haddle of response
      setTimeout(async () => {
        try {
          if (!this.payorSelect.loadInitialData) {
            const insuranceCompanies =
              await this.actions.getInsuranceCompanyList({
                limit: 10,
                limitData: [...this.limitData.insuranceCompany, "totalCount"],
              });
            this.payorSelect.loadInitialData = true;
            this.payorSelect.items =
              this.parseInsuranceCompanyResponseData(insuranceCompanies);
            this.payorSelect.totalCount = insuranceCompanies[0].totalCount;
          } else {
            if (this.payorSelect.isLazyLoading) {
              const insuranceCompanies =
                await this.actions.getInsuranceCompanyList({
                  limit: 10,
                  limitData: this.limitData.insuranceCompany,
                  offset: this.payorSelect.items.length,
                });
              this.payorSelect.items = this.payorSelect.items.concat(
                this.parseInsuranceCompanyResponseData(insuranceCompanies)
              );
            }
            this.payorSelect.isLazyLoading = false;
          }
          this.payorSelect.isLoading = false;
        } catch (e) {
          this.payorSelect.errorOccur = true;
        }
      }, 500);
    },
    async getMSPs() {
      // api call here
      // and haddle of response
      setTimeout(async () => {
        try {
          if (!this.mspSelect.loadInitialData) {
            const msps = await this.actions.getMSPList({
              limit: 10,
              limitData: [...this.limitData.msp, "totalCount"],
            });
            this.mspSelect.loadInitialData = true;
            this.mspSelect.items = this.parseMspResponseData(msps);
            this.mspSelect.totalCount = msps[0].totalCount;
          } else {
            if (this.mspSelect.isLazyLoading) {
              const msps = await this.actions.getMSPList({
                limit: 10,
                limitData: this.limitData.msp,
                offset: this.mspSelect.items.length,
              });
              this.mspSelect.items = this.mspSelect.items.concat(
                this.parseMspResponseData(msps)
              );
            }
            this.mspSelect.isLazyLoading = false;
          }
          this.mspSelect.isLoading = false;
        } catch (e) {
          this.mspSelect.errorOccur = true;
        }
      }, 500);
    },
    async getRelationshipTypes() {
      // api call here
      // and haddle of response
      setTimeout(async () => {
        try {
          if (!this.insuranceRelationshipSelect.loadInitialData) {
            const relationshipTypes =
              await this.actions.getInsuranceRelationshipTypes({
                limit: 10,
                limitData: [...this.limitData.relationshipType, "totalCount"],
              });
            this.insuranceRelationshipSelect.loadInitialData = true;
            this.insuranceRelationshipSelect.items =
              this.parseRelationsipResponseData(relationshipTypes);
            this.insuranceRelationshipSelect.totalCount =
              relationshipTypes[0].totalCount;
          } else {
            if (this.insuranceRelationshipSelect.isLazyLoading) {
              const relationshipTypes =
                await this.actions.getInsuranceRelationshipTypes({
                  limit: 10,
                  limitData: this.limitData.msp,
                  offset: this.insuranceRelationshipSelect.items.length,
                });
              this.insuranceRelationshipSelect.items =
                this.insuranceRelationshipSelect.items.concat(
                  this.parseMspResponseData(relationshipTypes)
                );
            }
            this.insuranceRelationshipSelect.isLazyLoading = false;
          }
          this.insuranceRelationshipSelect.isLoading = false;
        } catch (e) {
          this.insuranceRelationshipSelect.errorOccur = true;
        }
      }, 500);
    },
    async refetchData(endpoint) {
      if (endpoint.toUpperCase() == "PAYORS") {
        await this.getPayorsDebounce();
        this.payorSelect.errorOccur = false;
      } else if (endpoint.toUpperCase() == "MSPS") {
        await this.getMSPsDebounce();
        this.Select.errorOccur = false;
      } else if (endpoint.toUpperCase() == "RELATIONSHIPTYPES") {
        await this.getRelationshipTypesDebounce();
        this.Select.errorOccur = false;
      }
    },
    selectedChange(value) {
      if (value.payorGroup == "UKN") {
        this.__Firstname = "";
        this.__Lastname = "";
        this.__Address = "";
        this.__City = "";
        this.__State = "";
        this.__Zipcode = "";
        this.__Country = "";
        this.__AuthorizationNumber = "";
        this.__AuthorizationRequired = "";
        this.__GroupId = "";
        this.__GroupName = "";
        this.__RelationshipType = "";
        this.__MSP = "";
        this.__PlanId = "";
        this.__PlanName = "";
        this.__SubscriberId = "";
        this.$refs.form.resetValidation();
      }
    },
    atInput() {
      this.payorSelect.items = [{ text: "Loading", value: {}, disabled: true }];
      this.payorSelect.loadInitialData = false;
      this.payorSelect.isLoading = false;
      this.payorSelect.totalCount = 0;
    },
    async validatePayorItem() {
      const isValidated = [
        await this.$refs.addressSection.$refs.form.validateAsync(),
        await this.$refs.form.validateAsync(),
      ];
      return isValidated.filter((bool) => !bool).length === 0;
    },
    clearAddress() {
      this.__Address.line1 = "";
      this.__Address.country = "";
      this.__Address.postalCode = "";
      this.__Address.stateProvince = "";
      this.__Address.city = "";
    },
  },
  async created() {
    this.getPayorsDebounce = await this.debounce(
      async () => await this.getPayors()
    );
    this.getMSPsDebounce = await this.debounce(
      async () => await this.getMSPs()
    );
    this.getRelationshipTypesDebounce = await this.debounce(
      async () => await this.getRelationshipTypes()
    );
  },
  updated() {},
  mounted() {},
};
