import EnumModelActions from "store/actions/enum";

export default {
  name: "AddErrorModal",
  props: {
    insurances: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      actions: new EnumModelActions(),
      error: {
        id: null,
        active: false,
        isAdded: true,
        payor: {
          id: null,
          payorId: "",
        },
        hasCheckbox: false,
        deletable: true,
        errorCode: "",
        errorGroup: "",
        errorDescription: "",
        payorId: "N/A",
        billingErrorCode: {
          id: null,
          hierarchy: 0,
          errorCode: "",
          errorDescription: "",
          errorGroup: "",
        },
      },
      groupOptions: [],
      rules: {
        required: (val) =>
          (val !== null && val.length !== 0) || "This field is required.",
      },
    };
  },
  computed: {
    payorIds() {
      return this.insurances.map((item) => {
        const { insuranceCompany } = item;
        return { ...insuranceCompany };
      });
    },
  },
  methods: {
    selectedPayorId(e) {
      if (e !== null) {
        const { id, payorId } = e;
        this.error.payorId = payorId || "N/A";
        this.error.payor = { id, payorId };
      }
    },
    selectedErrorGroup(e) {
      if (e !== null) {
        const { errorGroup, hierarchy } = e;

        this.error.billingErrorCode = Object.assign(
          this.error.billingErrorCode,
          { errorGroup, hierarchy }
        );
      }
    },
    async addErrorCode() {
      const validate = await this.$refs.addErrorForm.validateAsync();
      if (validate) this.$emit("addErrorCode", this.error);
    },
    async loadErrorGroups() {
      try {
        if (!this.groupOptions.length) {
          this.groupOptions = [
            {
              errorGroup: "Fetching...",
              disabled: true,
            },
          ];

          const result = await this.actions.getEnumValues("BillingErrorGroup");

          if (result)
            this.groupOptions = result.enumValues.map((item, index) => {
              return {
                errorGroup: item.name,
                hierarchy: ++index,
              };
            });
        }
      } catch (err) {
        this.showNotifyMessage({
          message: `Unable to fetch data.`,
          type: "danger",
        });
        this.groupOptions = [];
      }
    },
  },
  created() {
    this.loadErrorGroups();
  },
};
