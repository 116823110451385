import moment from "moment-timezone";
import AccessionActions from "modules/actions/accession-actions";
import {
  COLUMNS,
  NAME,
  update_keys,
  float_keys,
  optionalAmounts,
} from "./constants";
import { isEmpty } from "lodash";

export default {
  name: NAME,
  components: {},
  props: {
    billingTransactionDetails: {
      default: [],
      type: Array,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      onEditState: false,
      actions: new AccessionActions(),
      billingTransactionDetailsCopy: [],
      datePicker: [],
      dateValues: [],
      rules: {
        validDate: (v) => moment(v).isValid() || "Invalid Date",
      },
      columns: COLUMNS,
      menu: false,
      modal: false,
      menu2: false,
      transactionDate: {},
      checkDate: {},
      expandedRow: [],
      noneNegativeValue: (val) =>
        val == 0 ||
        !val ||
        this.isNegative(val) ||
        "Currency cannot be less than 0",
    };
  },
  computed: {
    isEditing() {
      return !!this.__TransactionDetails.find(
        (transactionDetail) => transactionDetail.editable === true
      );
    },
    __TransactionDetails: {
      get() {
        return this.billingTransactionDetails;
      },
      set(value) {
        return this.$emit("update:billingTransactionDetails", value);
      },
    },
    shouldDisableFields() {
      return this.isSaving || !this.onEditState;
    },
  },
  methods: {
    expandRow(data, edit = false) {
      const index = this.expandedRow.findIndex((row) => row.id == data.id);
      if (index !== -1 && edit) return;
      if (index !== -1) this.expandedRow.splice(index, 1);
      else this.expandedRow.push(data);
    },
    updateTransactionValue(newTransaction) {
      this.__TransactionDetails = [
        ...this.__TransactionDetails.map((transaction) => {
          return transaction.id === newTransaction.id
            ? newTransaction
            : transaction;
        }),
      ];
      this.billingTransactionDetailsCopy = [...this.billingTransactionDetails];
    },
    formatDate(value, format = "MM/DD/YYYY") {
      if (!value) return null;
      return this.$options.filters.changeDateFormat(value, format);
    },
    edit(item, toCancel = false) {
      this.__TransactionDetails = [
        ...this.__TransactionDetails.map((tran, i) => {
          if (tran.id === item.id) {
            this.expandRow(tran, true);
            return toCancel
              ? {
                  ...this.billingTransactionDetailsCopy[i],
                  editable: false,
                }
              : {
                  ...tran,
                  editable: true,
                };
          }

          return tran;
        }),
      ];
    },
    addEntry() {
      const newEntryData = {
        uid: Math.random().toString,
        transactionDate: null,
        payorId: null,
        subscriberId: null,
        payorIcn: null,
        depositNumber: null,
        checkNumber: null,
        checkDate: null,
        cptCode: null,
        total: null,
        deductible: null,
        coPay: null,
        coInsurance: null,
        ptResp: null,
        paidAmount: null,
        adjAmount: null,
        adjDenialCode: null,
        payorPriority: null,
        user: null,
        paidAmounts: null,
        editable: true,
        loadInitialCurrencyValue: false,
      };

      this.datePicker = [
        {
          cDateShow: false,
          show: false,
          date: "",
          checkDate: "",
        },
        ...this.datePicker,
      ];

      this.__TransactionDetails = [newEntryData, ...this.__TransactionDetails];
    },
    cancel() {
      this.__TransactionDetails = this.billingTransactionDetailsCopy;
      this.loadInitialCurrencyValue = false;
      this.onEditState = false;
    },
    setTransactionDetails() {
      this.__TransactionDetails = this.__TransactionDetails.map((item) => ({
        ...item,
        ...item.computedValues,
        vbsCheckDate: item.vbsCheckDate
          ? this.formatDate(item.vbsCheckDate)
          : null,
        loadInitialCurrencyValue: false,
      }));

      this.billingTransactionDetailsCopy = [...this.billingTransactionDetails];

      this.billingTransactionDetailsCopy.forEach(() => {
        this.datePicker = [
          {
            cDateShow: false,
            show: false,
            date: "",
            checkDate: "",
          },
          ...this.datePicker,
        ];
      });
    },
    hasOneValuePerRow() {
      const fields = this.__TransactionDetails.filter(
        (value) => value.editable
      );
      const result = fields.every((value) => {
        return (
          value.transactionDate !== null ||
          value.payorId !== null ||
          value.subscriberId !== null ||
          (value.payorIcn !== null) | (value.depositNumber !== null) ||
          value.checkNumber !== null ||
          value.checkDate !== null ||
          value.cptCode !== null ||
          value.total !== null ||
          value.deductible !== null ||
          value.coPay !== null ||
          value.coInsurance !== null ||
          value.ptResp !== null ||
          value.paidAmount !== null ||
          value.adjAmount !== null ||
          value.adjDenialCode !== null ||
          value.payorPriority !== null ||
          value.user !== null ||
          value.paidAmounts !== null
        );
      });
      return result;
    },

    async saveTransaction() {
      let ids = [];
      try {
        this.isSaving = true;

        if (!this.hasOneValuePerRow()) {
          this.showNotifyMessage({
            message: `Fillout atleast 1 field per row in Transaction Details`,
            type: "danger",
          });

          this.isSaving = false;
          return;
        }

        for (const iterator of this.__TransactionDetails.entries()) {
          const transaction = iterator[1];
          if (transaction.editable) {
            if (transaction.id) {
              const payload = this.getPayload(transaction, "UPDATE");
              if (!isEmpty(payload)) {
                await this.actions.updateBillingTransactionDetails(payload);
                delete transaction.editable;
                transaction.loadInitialCurrencyValue = false;
                ids.push(transaction.id);
                this.updateTransactionValue(transaction);
              } else {
                throw new SyntaxError("Empty transaction");
              }
            } else {
              const payload = this.getPayload(transaction, "CREATE");
              if (!isEmpty(payload)) {
                const response =
                  await this.actions.createBillingTransactionDetails(payload);
                delete transaction.uid;
                delete transaction.editable;
                transaction.loadInitialCurrencyValue = false;
                ids.push(response.id);
                transaction.id = response.id;
                this.updateTransactionValue(transaction);
              } else {
                throw new SyntaxError("Empty transaction");
              }
            }
          }
        }
        if (!isEmpty(ids)) {
          this.showNotifyMessage({
            message: `Transaction Details has been saved.`,
            type: "success",
          });
          this.updateAccession(ids);
          this.$emit(
            "update:billingTransactionDetailsCopy",
            this.billingTransactionDetailsCopy
          );
        }

        this.isSaving = false;
        this.onEditState = false;
      } catch (err) {
        this.isSaving = false;
        this.onEditState = false;

        this.showNotifyMessage({
          message: `Problem has occurred while saving data.`,
          type: "danger",
        });
      }
    },
    async validateFields() {
      const entities = Object.entries(this.$refs);
      let validated = [];
      for (let i = 0; i < entities.length; i++) {
        validated.push(await entities[i][1].validateAsync());
      }
      return validated.filter((bool) => !bool);
    },
    async saveRowTransaction(index) {
      if ((await this.validateFields()).length != 0) return;
      let transaction = this.__TransactionDetails[index];
      this.isSaving = true;
      let ids = [];

      try {
        if (!this.hasOneValuePerRow()) {
          this.showNotifyMessage({
            message: `Fillout atleast 1 field per row in Transaction Details`,
            type: "danger",
          });

          this.isSaving = false;
          return;
        }

        transaction.adjAmount = transaction.revenueAdjAmount;

        if (transaction.editable) {
          if (transaction.id) {
            const payload = this.getPayload(transaction, "UPDATE");
            if (!isEmpty(payload)) {
              await this.actions.updateBillingTransactionDetails(payload);
              transaction.loadInitialCurrencyValue = false;
              this.updateTransactionValue(transaction);
            } else {
              throw new SyntaxError("Empty transaction");
            }
          } else {
            const payload = this.getPayload(transaction, "CREATE");
            if (!isEmpty(payload)) {
              const response =
                await this.actions.createBillingTransactionDetails(payload);
              delete transaction.uid;
              delete transaction.editable;
              transaction.loadInitialCurrencyValue = false;
              ids.push(response.id);
              this.updateTransactionValue(transaction);
            } else {
              throw new SyntaxError("Empty transaction");
            }
          }
        }
        if (!isEmpty(ids)) {
          await this.updateAccession(ids);
          await this.$emit(
            "update:billingTransactionDetails",
            this.billingTransactionDetails
          );
        }

        await this.updateTransaction(transaction.id, index);

        transaction.editable = false;

        this.showNotifyMessage({
          message: `Transaction Details has been saved.`,
          type: "success",
        });

        this.isSaving = false;
      } catch (err) {
        this.isSaving = false;
        this.showNotifyMessage({
          message: `Problem has occurred while saving data.`,
          type: "danger",
        });
      }
    },
    async updateTransaction(id, index) {
      const result = await this.actions.getAccessionEntryById({
        limitData: [
          this.buildSubQuery("billingTransactionDetails", [
            this.buildSubQuery("computedValues", ["billedAmount", "revenueAdjAmount", "balance"], false),
          ]),
        ],
        variables: {
          property: {
            id: {
              type: "UUID!",
              value: this.$route.params.id,
            },
          },
        },
      });
      this.__TransactionDetails[index].computedValues.balance =
        result.billingTransactionDetails.find(
          (trans) => trans.id === id
        ).computedValues.balance;
    },
    getPayload(transaction, type) {
      switch (type) {
        case "UPDATE": {
          let payload = {
            collectionQueries: [],
            queries: {},
            billingTransactionDetailsId: {
              type: "UUID!",
              value: transaction.id,
              unincludeToFields: true,
            },
          };

          // let hasChanges = false;
          for (const [key] of Object.entries(transaction)) {
            if (
              ((transaction[key] || key == "adjAmount") && update_keys[key]) ||
              optionalAmounts.includes(key)
            ) {
              // hasChanges = true;
              payload.collectionQueries.push({
                [update_keys[key]]: {
                  [key]: {
                    type: float_keys.includes(key) ? "Float!" : "String!",
                    value: float_keys.includes(key)
                      ? transaction[key] !== null
                        ? parseFloat(transaction[key] || 0)
                        : 0
                      : key == "vbsCheckDate"
                      ? this.formatDate(transaction[key], "YYYY-MM-DD 00:00:00")
                      : key == "adjAmount" && !transaction[key].length
                      ? "0"
                      : transaction[key].toString(),
                    unincludeToFields: true,
                  },
                },
              });
            }
          }
          return payload;
        }

        case "CREATE": {
          let payload = {
            payload: {
              payorId: {
                type: "String",
                value: transaction["payorId"] || "",
              },
            },
            collectionQueries: [],
            queries: {},
          };

          let hasChanges = transaction["payorId"] ? true : false;
          for (const [key] of Object.entries(transaction)) {
            if (!["payorId"].includes(key) && transaction[key]) {
              if (update_keys[key]) {
                hasChanges = true;
                payload.collectionQueries.push({
                  [update_keys[key]]: {
                    [key]: {
                      type: float_keys.includes(key) ? "Float!" : "String!",
                      value: transaction[key],
                      unincludeToFields: true,
                    },
                  },
                });
              }
            }
          }

          return hasChanges ? payload : {};
        }
      }
    },
    async updateAccession(ids) {
      const collectionQueries = this.generateCollectionQueries(ids);
      await this.actions.updateAccessionDetails({
        collectionQueries: collectionQueries,
        childQueries: [],
        orderId: {
          value: this.id,
          type: "UUID!",
          unincludeToFields: true,
        },
      });
    },
    generateCollectionQueries(ids) {
      let collectionQueries = [
        ...ids.map((id) => {
          return {
            addBillingTransactionDetails: {
              billingTransactionDetailsId: {
                value: id,
                type: "UUID!",
              },
            },
          };
        }),
      ];

      return collectionQueries;
    },
    populateCurrencies(item) {
      item.loadInitialCurrencyValue = true;
    },
    isNegative(num) {
      return Math.sign(parseInt(num)) == 1;
    },
    computeItemBalance(index, value = 0, isPaidAmount = false) {
      const { computedValues, revenueAdjAmount, vbsPaidAmount } = this.__TransactionDetails[index];
      const { billedAmount } = computedValues;
      const newBalance =  billedAmount - value - (isPaidAmount ? revenueAdjAmount : vbsPaidAmount);
      this.__TransactionDetails[index].computedValues.balance = newBalance;
    }
  },
  created() {
    this.setTransactionDetails();
  },
};
