// import Order from "../../../store/models/Order";
import AccessionActions from "modules/actions/accession-actions";
import AccessionDetails from "./section/AccessionDetails";
import DiagnosisCodes from "./section/DiagnosisCodes";
import Notes from "./section/InternalAccessionNotes";
import PatientDemographics from "./section/PatientDemographics";
import PayorIDSelection from "./section/PayorIDSelection";
import PhysicianInformation from "./section/PhysicianInformation";
import TestOrdered from "./section/TestOrdered";
import BillingHistory from "./section/BillingHistory";
import EOBDepositInformation from "./section/EOBDepositInformation";
import TransactionDetails from "./section/TransactionDetails";
import ErrorCodes from "./section/ErrorCodes";
import isEqual from "lodash/isEqual";
import isEqualWith from "lodash/isEqualWith";

export default {
  name: "Accession",
  components: {
    AccessionDetails,
    DiagnosisCodes,
    Notes,
    PatientDemographics,
    PayorIDSelection,
    PhysicianInformation,
    TestOrdered,
    BillingHistory,
    EOBDepositInformation,
    TransactionDetails,
    ErrorCodes,
  },
  data() {
    return {
      actions: new AccessionActions(),
      panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      accession: {
        patient: { info: {} },
      },
      accessionCopy: {
        patient: { info: {} },
        physician: { orderingPhysician: {}, referringPhysician: {} },
        insurances: [],
      },
      defaultData: {},
      dialog: false,
      isLoading: false,
    };
  },
  computed: {
    isMedicare() {
      if (!this.accessionCopy.insurances.length) return false;

      const prior1 = this.accessionCopy.insurances[0];

      return prior1.insuranceCompany.payorSubGroup.includes("Medicare");
    },
    testOrderedEditted() {
      const keys = Object.keys(this.accession.test.cptCode);

      for (let i = 0; i < keys.length; i++)
        if (
          this.accession.test.cptCode[keys[i]] !=
          this.accessionCopy.test.cptCode[keys[i]]
        )
          return true;

      return false;
    },
    payorIdChanges() {
      try {
        if (this.accession.id) {
          const insurance = this.accession.insurances;
          const insuranceCopy = this.accessionCopy.insurances;

          if (insurance.length != insuranceCopy.length) {
            return true;
          }

          for (let i = 0; i < insuranceCopy.length; i++) {
            const insurancesKeys = Object.keys(insuranceCopy[i]);

            for (let z = 0; z < insurancesKeys.length; z++) {
              if (insurancesKeys[z] == "insured") {
                let insuredKeys = Object.keys(insuranceCopy[i].insured);

                for (let b = 0; b < insuredKeys.length; b++)
                  if (insuredKeys[b] == "defaultAddress") {
                    let addressKeys = Object.keys(
                      insuranceCopy[i].insured.defaultAddress
                    );
                    for (let a = 0; a < addressKeys; a++) {
                      insurance[i].insured.defaultAddress[addressKeys[a]] !=
                        insuranceCopy[i].insured.defaultAddress[addressKeys[a]];
                      if (
                        insurance[i].insured.defaultAddress[addressKeys[a]] !=
                        insuranceCopy[i].insured.defaultAddress[addressKeys[a]]
                      ) {
                        return true;
                      }
                    }
                  } else if (
                    ["firstName", "lastName", "middleName"].includes(
                      insuredKeys[b]
                    )
                  )
                    if (
                      insurance[i].insured[insuredKeys[b]] !=
                      insuranceCopy[i].insured[insuredKeys[b]]
                    ) {
                      return true;
                    }
              } else if (
                typeof insuranceCopy[i][insurancesKeys[z]] == "object"
              ) {
                if (
                  insurance[i][insurancesKeys[z]].id !=
                  insuranceCopy[i][insurancesKeys[z]].id
                ) {
                  return true;
                }
              } else if (
                insurance[i][insurancesKeys[z]] !=
                insuranceCopy[i][insurancesKeys[z]]
              ) {
                return true;
              }
            }
          }

          return false;
        } else return false;
      } catch (e) {
        return false;
      }
    },
  },
  methods: {
    async getAccessionDetails() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        if (this.$route.params?.id) {
          const result = await this.actions.getAccessionEntryById({
            variables: {
              property: {
                id: {
                  type: "UUID!",
                  value: this.$route.params.id,
                },
                ["orderBillingErrorCodes.orderByDate"]: {
                  type: "DateOrderSort",
                  value: {
                    dateField: "CREATED",
                    orderBy: "DESC",
                  }
                },
              },
            },
          });

          this.actions.copyOrderModelToAccessionEntryModel({ id: result.id });
          const accession = await this.actions.getProcessingAccession();
  
          this.accession = Object.assign(this.accession, accession);
          accession.insurances = this.sortInsurances(accession.insurances);
  
          accession.billingDiagnosticCode = this.updateDiagnosticCode(
            accession.billingDiagnosticCode
          );
  
          this.populateAccessionEntry(JSON.parse(JSON.stringify(accession)));
        } else {
          this.redirectToOrders();
        }
      } catch (err) {
        this.showNotifyMessage({
          message: `Unable to fetch data.`,
          type: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
    populateAccessionEntry(accession) {
      if (accession) {
        this.accession = {
          ...this.accession,
          ...accession,
        };
        this.accessionCopy = {
          ...this.accessionCopy,
          ...accession,
        }
        this.defaultData = JSON.parse(
          JSON.stringify({
            accessionDetails: {
              firstName: this.accessionCopy.patient.info.firstName,
              lastName: this.accessionCopy.patient.info.lastName,
              dateOfBirth: this.accessionCopy.patient.info.dateOfBirth,
              gender: this.accessionCopy.patient.info.gender,
              abn: this.accessionCopy.abn,
              billingStatementStatus: this.accessionCopy.billingStatementStatus,
              billingNetsuiteStatus: this.accessionCopy.billingNetsuiteStatus,
            },
            patient: this.accessionCopy.patient,
            physician: {
              orderingPhysician: this.accessionCopy.physician.orderingPhysician,
              referringPhysician: this.accessionCopy.physician.referringPhysician,
            }
          }),
        );
      }
    },
    sortInsurances(insurances) {
      const sortedInsurances = insurances.sort((a, b) => {
        if (a.payorPriority < b.payorPriority) return -1;
        else return 1;
      });

      const updatePriority = sortedInsurances.map((payor, i) => {
        if (payor.payorPriority !== i + 1)
          return { ...payor, payorPriority: i + 1 };
        else return { ...payor };
      });
      return updatePriority;
    },
    addPayor(payor) {
      const payorUsed = this.accession.insurances.filter(
        (item) => item.insuranceCompany.id === payor.insuranceCompany.id
      ).length;

      if (payorUsed) {
        this.showNotifyMessage({
          message: "Payor ID already exists.",
          type: "danger",
        });
      } else
        this.accession.insurances.push({
          ...payor,
          authorizationNumber: "",
          authorizationRequired: "",
          groupId: "",
          groupName: "",
          id: "",
          insurancePlanName: "",
          insuranceRelationshipType: null,
          insured: {
            id: null,
            firstName: "",
            lastName: "",
            middleName: "",
            defaultAddress: {
              id: null,
              line1: "",
              line2: "",
              city: "",
              stateProvince: "",
              postalCode: "",
              country: "",
            },
          },
          msp: null,
          planId: "",
          subscriberId: "",
          note: {
            id: null,
            note: "",
          },
        });
    },

    cancelChanges(data) {
      if (data.section.toUpperCase() == "PAYORIDSELECTION")
        this.accession.insurances = this.accessionCopy.insurances.map(
          (insurance) => JSON.parse(JSON.stringify(insurance))
        );
      else if (data.section.toUpperCase() == "ACCESSIONDETAILS") {
        this.accession.patient.info = JSON.parse(
          JSON.stringify(this.accessionCopy.patient.info)
        );
        const toUpdate = [
          "billingAccessionStatus",
          "billingStatementStatus",
          "billingNetsuiteStatus",
          "netsuiteInvoice",
          "billedAmount",
          "balanceDue",
          "priceDate",
          "abn",
        ];

        for (let i = 0; i < toUpdate.length; i++)
          this.accession[toUpdate[i]] = this.accessionCopy[toUpdate[i]];
      } else if (data.section.toUpperCase() == "TESTORDERED")
        this.accession.testOrdered = JSON.parse(
          JSON.stringify(this.accessionCopy.testOrdered)
        );
    },
    updateAccession() {
      this.accessionCopy.insurances = this.accession.insurances.map(
        (insurance) => JSON.parse(JSON.stringify(insurance))
      );
    },
    async updateAccessionDetailsChanges(props) {
      let changeLog = {};
      for (let i = 0; i < props.length; i++) {
        if (this.objectHasOwnProperty(this.accessionCopy, props[i])) {
          if (typeof this.accession[props[i]] == "object") continue;
          this.accessionCopy[props[i]] = this.accession[props[i]];
        }
        if (
          this.objectHasOwnProperty(this.accessionCopy.patient.info, props[i])
        ) {
          if (
            this.accessionCopy.patient.info[props[i]] !=
            this.accession.patient.info[props[i]]
          ) {
            changeLog[`patient.${props[i]}`] = {
              new: this.accession.patient.info[props[i]],
              old: this.accessionCopy.patient.info[props[i]],
            };
          }
        }
      }

      await this.actions.sendChangeLogNotification({
        params: {
          orderId: {
            type: "UUID!",
            value: this.accession.id,
            unincludeToFields: true,
          },
          content: {
            type: "String!",
            value: JSON.stringify(changeLog),
            unincludeToFields: true,
          },
          permissions: {
            type: "[String]!",
            value: ["KEY_FIELDS_NOTIFICATION"],
            unincludeToFields: true,
          },
        },
      });

      this.accessionCopy.patient.info = JSON.parse(
        JSON.stringify(this.accession.patient.info)
      );
    },
    updateAccessionTestOrdered() {
      this.accessionCopy.testOrdered = JSON.parse(
        JSON.stringify(this.accession.testOrdered)
      );
    },
    compareBy(obj, objectToCompare, key) {
      return isEqualWith(obj, objectToCompare, (value, otherValue) => {
        return value[key] === otherValue[key];
      });
    },
    saveAccession() {
      const TO_CHECK = [
        "billingNetsuiteStatus",
        "netsuiteInvoice",
        "billedAmount",
        "balanceDue",
        "priceDate",
        "patient",
        "billingAccessionStatus",
        "billingStatementStatus",
        "abn",
        "addedOrderBillingErrorCodes",
        "notes",
        "patientSignatureType",
        "physician",
        "billingDiagnosticCode",
        "priorAuthorization",
        "insurance",
        "testOrdered",
        "billingHistory",
        "billingTransactionDetails",
        "eobDepositInformation",
      ];

      let hasChanges = false;
      for (const element of TO_CHECK) {
        const a = this.accession[element];
        const aC = this.accessionCopy[element];
        if (
          [
            "addedOrderBillingErrorCodes",
            "billingTransactionDetails",
            "eobDepositInformation",
          ].includes(element)
        ) {
          if (isEqual(a.length, aC.length)) {
            if (!this.compareBy(a, aC, "id")) {
              hasChanges = true;
              break;
            }
          } else {
            hasChanges = true;
            break;
          }
        } else if (element === "patient") {
          // check phones
          if (
            (a.phones.countryCode &&
              aC.phones.countryCode &&
              a.phones.phone &&
              aC.phones.phone &&
              a.phones.countryCode.code !== aC.phones.countryCode.code) ||
            a.phones.phone !== aC.phones.phone
          ) {
            hasChanges = true;
            break;
          }
          //check defaultPhone
          if (
            a.defaultPhone.areaCode.replace(/[()]/g, "") !==
              aC.defaultPhone.areaCode ||
            a.defaultPhone.phone.replace(/-/g, "") !== aC.defaultPhone.phone
          ) {
            hasChanges = true;
            break;
          }

          // check default address
          if (!isEqual(a.defaultAddress, aC.defaultAddress)) {
            hasChanges = true;
            break;
          }

          if (!isEqual(a.info, aC.info)) {
            hasChanges = true;
            break;
          }
        } else {
          if (!isEqual(a, aC)) {
            hasChanges = true;
            break;
          }
        }
      }
      if (hasChanges) {
        this.dialog = true;
      } else {
        this.redirectToOrders();
      }
    },
    redirectToOrders() {
      this.$router.push("/orders");
    },
    updateDiagnosticCode(diagCode) {
      return diagCode.map((code) => {
        code.active = !code.active;
        return code;
      });
    },
  },
  async created() {
    this.getAccessionDetails();
  },
};
