var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"white"},[_c('v-form',[_c('v-row',[_c('v-col',[(!_vm.onEditState)?_c('v-btn',{staticClass:"text-uppercase first-child-btn",attrs:{"depressed":"","text":"","color":"primary","disabled":_vm.btnsDisabled},on:{"click":function($event){_vm.onEditState = true}}},[_vm._v("Edit")]):_c('v-switch',{staticClass:"hide-inactive",attrs:{"hide-details":"","label":"Hide Inactive Errors","color":"primary","disabled":_vm.btnsDisabled},model:{value:(_vm.hideInactive),callback:function ($$v) {_vm.hideInactive=$$v},expression:"hideInactive"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"id":"dataTableCol"}},[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.rerunningPricingRules),expression:"rerunningPricingRules"}],staticClass:"loader-container",style:({ height: _vm.tBodyHeight })},[_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('div',{staticClass:"loader-background"}),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"color":"light-blue","height":"20","value":_vm.progressValue,"striped":""}})],1)],1)],1),_c('v-custom-datatable',{ref:"dataTable",staticClass:"data-table",attrs:{"row-key":"id","id":"dataTable","noDataLabel":"No Error Code Found","bordered":"","text":"","data":_vm.errorCodesList,"columns":_vm.columns,"isLoading":_vm.isLoading,"totalCount":_vm._TotalCount,"withSearchFilter":false,"accessControl":{
            authUserHasAddAccessControl: true,
            authUserHasViewAccessControl: true,
            authUserHasEditAccessControl: true,
            authUserHasDeleteAccessControl: true,
          },"disableCheckbox":{
            active: !_vm.onEditState,
          }},on:{"executeQuery":_vm.paginationMethod,"deleteIconAction":_vm.removeErrorCode}})],1)],1),(_vm.onEditState)?_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"first-child-btn",attrs:{"color":"primary","depressed":"","disabled":_vm.btnsDisabled},on:{"click":function($event){_vm.errorModalVisibility = true}}},[_vm._v(" Add Error ")])],1),_c('v-col',{staticClass:"text-right d-inline-block"},[_c('v-btn',{attrs:{"depressed":"","disabled":_vm.btnsDisabled},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"depressed":"","loading":_vm.isGeneratingErrorCodes,"disabled":_vm.btnsDisabled},on:{"click":_vm.generateErrorCodes},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-icon',{staticClass:"icon-refresh loader"},[_vm._v("mdi-refresh")])]},proxy:true}],null,false,2147955927)},[_vm._v(" Refresh List ")]),_c('v-btn',{attrs:{"depressed":"","color":"primary","loading":_vm.rerunningPricingRules,"disabled":_vm.btnsDisabled},on:{"click":_vm.rerunPricingRules},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-icon',{staticClass:"icon-refresh loader"},[_vm._v("mdi-refresh")])]},proxy:true}],null,false,2147955927)},[_vm._v(" Re-run Pricing Rules ")]),_c('v-btn',{attrs:{"depressed":"","color":"primary","loading":_vm.isSavingErrorCodes,"disabled":_vm.btnsDisabled},on:{"click":_vm.saveAddedErrorCodes},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',[_vm._v("Saving...")])]},proxy:true}],null,false,451671709)},[_vm._v(" Save Added Error Codes ")])],1)],1):_vm._e()],1),(_vm.errorModalVisibility)?_c('v-dialog',{attrs:{"persistent":"","width":"34rem"},model:{value:(_vm.errorModalVisibility),callback:function ($$v) {_vm.errorModalVisibility=$$v},expression:"errorModalVisibility"}},[_c('AddErrorModal',{attrs:{"insurances":_vm.insurances},on:{"addErrorCode":_vm.addErrorCode,"onCancel":function($event){_vm.errorModalVisibility = $event}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }