var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"white"},[_c('v-form',[_c('v-row',[_c('v-col',[(!_vm.onEditState)?_c('v-btn',{staticClass:"text-uppercase first-child-btn",attrs:{"depressed":"","text":"","color":"primary"},on:{"click":function($event){_vm.onEditState = true}}},[_vm._v("Edit")]):_c('v-switch',{attrs:{"label":"Show Inactive DX Codes","disabled":_vm.isSavingCodes},on:{"change":_vm.showInactiveItems},model:{value:(_vm.showInactive),callback:function ($$v) {_vm.showInactive=$$v},expression:"showInactive"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"diagnosis-table",attrs:{"headers":_vm.headers,"items":_vm.dataTable},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',[_vm._v(_vm._s(item.diagnosticCode.code))]),_c('td',[_vm._v(_vm._s(item.diagnosticCode.description || "--"))]),_c('td',[_vm._v(" "+_vm._s(item.addedBy.id !== null ? item.addedBy.email : "systemauto")+" ")]),_c('td',[_vm._v(_vm._s(item.addedDate || "--"))]),_c('td',[_vm._v(_vm._s(item.notes || "Data transfer from LIMS"))]),_c('td',{key:_vm.refresher},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-checkbox',_vm._g(_vm._b({staticClass:"set-action",attrs:{"color":"primary","hide-details":"","disabled":_vm.shouldDisableAction},on:{"change":function($event){return _vm.updateStatus(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},'v-checkbox',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.active ? "Deactivated" : "Active")+" ")])])],1),_c('td',[(_vm.onEditState)?_c('div',{staticClass:"sort-actions"},[(!_vm.isFirstIndex(index))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeOrderPriority(item, 'asc')}}},[_c('v-icon',{staticClass:"ma-0"},[_vm._v("mdi-chevron-up")])],1):_vm._e(),(!_vm.isLastIndex(index, _vm.dataTable))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeOrderPriority(item, 'desc')}}},[_c('v-icon',{staticClass:"ma-0"},[_vm._v("mdi-chevron-down")])],1):_vm._e()],1):_vm._e()]),_c('td',[(_vm.isRemovable(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"closeBtn"},on:{"click":function($event){return _vm.removeCode(item.uuid)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()],1)])]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("Diagnosis Codes not found.")])]},proxy:true}])})],1)],1),(_vm.onEditState)?_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"first-child-btn",attrs:{"depressed":"","color":"primary","disabled":_vm.isSavingCodes},on:{"click":_vm.showAddDiagnosisModal}},[_vm._v("Add Diagnostic Code")])],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"disabled":_vm.isSavingCodes},on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"text-right last-child-btn",attrs:{"color":"primary","disabled":_vm.isSavingCodes},on:{"click":_vm.saveAddedCode}},[_vm._v("Save ")])],1)],1):_vm._e()],1),(_vm.addDiagnosisModalVisibility)?_c('v-dialog',{attrs:{"width":"34rem","persistent":""},model:{value:(_vm.addDiagnosisModalVisibility),callback:function ($$v) {_vm.addDiagnosisModalVisibility=$$v},expression:"addDiagnosisModalVisibility"}},[_c('AddDiagnosisModal',{attrs:{"dateOfService":_vm.dateOfService,"excludedOnSearch":_vm.excludedOnSearch},on:{"addDiagnosisCode":_vm.addDiagnosisCode,"closeModal":function($event){_vm.addDiagnosisModalVisibility = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }