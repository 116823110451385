import { uuid } from "vue-uuid";

import AddInternalNoteModal from "modules/orders/OrderEntry/modals/AddInternalNoteModal";

import AccessionActions from "modules/actions/accession-actions";
import Actions from "modules/actions/";
import moment from "moment";

export default {
  name: "InternalAccessionNotes",
  components: { AddInternalNoteModal },
  props: {
    notes: {
      type: Array,
      required: true,
    },
    accessionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      actions: new AccessionActions(),
      userActions: new Actions(),
      note: "",
      date: "",
      id: 1,
      isSaving: false,
      onEditState: false,
      columns: [
        {
          text: "Internal Accession Notes",
          align: "left",
          sortable: true,
          value: "note",
        },
        {
          text: "User ID",
          align: "left",
          sortable: true,
          value: "createdBy",
        },
        {
          text: "Date/Time",
          align: "left",
          sortable: true,
          value: "created",
        },
        {
          text: "Action",
          align: "left",
          sortable: true,
          value: "action",
        },
      ],
      rules: {
        required: (val) =>
          (val !== null && val.length !== 0) || "This field is required.",
      },
    };
  },
  computed: {
    __Notes: {
      get() {
        return this.notes.sort(
          (a, b) => new Date(b.created) - new Date(a.created)
        );
      },
      set(value) {
        this.$emit("update:notes", value);
      },
    },
  },
  methods: {
    isDisable() {
      return !this.note;
    },
    moment() {
      return moment();
    },
    remove(id, index) {
      const toDelete = this.__Notes[index];
      if (toDelete.createdBy.id && toDelete.createdBy.id === id)
        this.__Notes.splice(index, 1);
    },
    addNote(note) {
      try {
        const date = new Date().toString();
        const user = this.actions.getUser();
        this.__Notes.push({
          uuid: uuid.v1(),
          id: null,
          note: note,
          created: date,
          createdBy: {
            id: user.id,
            username: user.email,
          },
        });

        this.dialog = false;
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });

        this.dialog = false;
      }
    },
    isRemovable(item) {
      return item.id === null;
    },
    async save() {
      try {
        this.isSaving = true;
        const notes = this.__Notes;
        for (var i = 0; i < notes.length; i++) {
          if (notes[i].id === null) {
            const result = await this.actions.updateAccessionDetails({
              orderId: {
                type: "UUID!",
                value: this.accessionId,
                unincludeToFields: true,
              },
              queries: {
                addNote: {
                  note: {
                    type: "String!",
                    value: notes[i].note,
                  },
                },
              },
              collectionQueries: [],
            });

            if (result && result.addNote) {
              this.__Notes = [];
              const limitData = [
                this.buildSubQuery("notes", [
                  "note",
                  "created",
                  this.buildSubQuery("createdBy", ["username"]),
                ]),
              ];
              const getOrderNotes = await this.actions.getOrderEntryById({
                variables: {
                  property: {
                    id: {
                      type: "UUID!",
                      value: this.accessionId,
                    },
                    ["orderBillingErrorCodes.orderByDate"]: {
                      type: "DateOrderSort",
                      value: {
                        dateField: "CREATED",
                        orderBy: "DESC",
                      }
                    }
                  },
                },
                limitData,
              });
              if (getOrderNotes.notes.length)
                this.__Notes = getOrderNotes.notes.map((note) => {
                  return {
                    id: note.id,
                    note: note.note,
                    createdBy: note.createdBy,
                    created: note.created,
                  };
                });
            }
          }
        }
        this.$emit("update:notesCopy", this.__Notes);

        this.showNotifyMessage({
          message: "Internal notes saved.",
          type: "success",
        });
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      } finally {
        this.isSaving = false;
        this.onEditState = false;
      }
    },
    cancel() {
      this.onEditState = false;
      this.__Notes = this.__Notes.filter((item) => item.id != null);
    },
  },
};
