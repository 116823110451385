import moment from "moment-timezone";
import AccessionActions from "modules/actions/accession-actions";
import isEmpty from "lodash/isEmpty";

export default {
  name: "EOBDepositInformation",
  components: {},
  props: {
    depositInformations: {
      default: [],
      type: [Array, Object],
    },
    orderId: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      actions: new AccessionActions(),
      isSaving: false,
      onEditState: false,
      informations: [],
      datePicker: [],
      rules: {
        validDate: (v) => !v || moment(v).isValid() || "Invalid Date",
        amount: (val) =>
          !val || /^\d+(\.\d{1,2})?$/.test(val) || "Input invalid (eg. 123.45)",
        required: (val) => !!val || "This field is required.",
      },
      columns: [
        {
          text: "Date",
          align: "left",
          sortable: false,
          value: "date",
        },
        {
          text: "Payor ID",
          align: "left",
          sortable: false,
          value: "code",
        },
        {
          text: "Subscriber ID",
          align: "left",
          sortable: false,
          value: "subscriberId",
        },
        {
          text: "Payor ICN",
          align: "left",
          sortable: false,
          value: "payorIcn",
        },
        {
          text: "Deposit #",
          align: "left",
          sortable: false,
          value: "depositNumber",
        },
        {
          text: "Check #",
          align: "left",
          sortable: false,
          value: "checkNumber",
        },
        {
          text: "Check Date",
          align: "left",
          sortable: false,
          value: "checkDate",
        },
        {
          text: "Paid Amount",
          align: "left",
          sortable: false,
          value: "paidAmount",
        },
        {
          text: "",
          align: "left",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    editingRow() {
      const { informations } = this;
      for (let i = 0; i < informations.length; i++) {
        if (informations[i].editable) return true;
      }
      return false;
    },
    shouldDisableFields() {
      return this.isSaving || !this.onEditState;
    },
  },
  methods: {
    addEntry() {
      const information = {
        id: null,
        date: "",
        loadInitialCurrencyValue: false,
        payorId: "",
        subscriberId: "",
        depositNumber: "",
        payorIcn: "",
        checkNumber: "",
        checkDate: "",
        paidAmount: "",
        editable: true,
      };
      this.informations.unshift(information);

      this.datePicker.unshift({
        cDateShow: false,
        show: false,
        date: "",
        checkDate: "",
      });
    },
    cancel() {
      if (!this.depositInformations || this.depositInformations.length == 0) {
        this.informations = [];
      } else {
        this.informations = this.depositInformations.map((info) =>
          JSON.parse(JSON.stringify(info))
        );
      }

      this.onEditState = false;
    },
    edit(item) {
      this.informations = [
        ...this.informations.map((info) => {
          if (info.id === item.id) {
            return {
              ...info,
              editable: true,
            };
          }
          return info;
        }),
      ];
    },
    hasOneValuePerRow() {
      const { informations } = this;
      const fields = informations.filter((value) => value.editable);
      const result = fields.every((value) => {
        return (
          value.date.length !== 0 ||
          value.subscriberId.length !== 0 ||
          value.payorIcn.length !== 0 ||
          value.depositNumber.length !== 0 ||
          value.checkNumber.length !== 0 ||
          value.checkDate.length !== 0 ||
          value.paidAmount.length !== 0 ||
          value.payorId.length !== 0
        );
      });
      return result;
    },
    async submit() {
      try {
        this.isSaving = true;
        const validate = await this.$refs.eobDepositForm.validateAsync();
        if (!validate) return;

        if (!this.hasOneValuePerRow()) {
          this.showNotifyMessage({
            message: `Fillout atleast 1 field per row in EOB Deposit Information`,
            type: "danger",
          });

          this.isSaving = false;
          return;
        }

        let throwError = false;
        let newlyCreated = [];
        const { informations } = this;
        for (let i = 0; i < informations.length; i++) {
          if (informations[i].editable) {
            if (informations[i].id) {
              const payload = this.getPayload(informations[i], "UPDATE");
              if (isEmpty(payload.queries)) {
                throwError = true;
              } else {
                await this.actions.updateEobDepositInformation(payload);
                delete informations[i].editable;
                informations[i].loadInitialCurrencyValue = false;
              }
            } else {
              const payload = this.getPayload(informations[i], "CREATE");
              if (isEmpty(payload.payload)) {
                throwError = true;
              } else {
                const response = await this.actions.createEobDepositInformation(
                  payload
                );
                delete informations[i].editable;
                informations[i].loadInitialCurrencyValue = false;
                newlyCreated.push(response.id);
                this.informations[i].id = response.id;
              }
            }
          }
        }

        if (throwError) {
          throw new SyntaxError("Empty object");
        }

        if (newlyCreated.length) {
          await this.updateAccession(newlyCreated);
        }

        this.updatEobDepositInformation();
        this.showNotifyMessage({
          message: `Deposit information have been updated.`,
          type: "success",
        });
        this.isSaving = false;
        this.onEditState = false;
      } catch (e) {
        this.showNotifyMessage({
          message: `Problem has occurred while saving data.`,
          type: "danger",
        });
        this.onEditState = false;
        this.isSaving = false;
      }
    },
    updatEobDepositInformation() {
      this.$emit(
        "update:depositInformations",
        this.informations.map((info) => JSON.parse(JSON.stringify(info)))
      );
      this.$emit(
        "update:depositInformationsCopy",
        this.informations.map((info) => JSON.parse(JSON.stringify(info)))
      );
    },
    async updateAccession(arr) {
      let collectionQueries = [];

      for (let i = 0; i < arr.length; i++)
        collectionQueries.push({
          addEobDepositInformation: {
            eobDepositInformationId: {
              value: arr[i],
              type: "UUID!",
            },
          },
        });

      this.actions.updateAccessionDetails({
        collectionQueries,
        orderId: {
          value: this.orderId,
          type: "UUID!",
          unincludeToFields: true,
        },
      });
    },
    parseData(data, type) {
      const keys = Object.keys(data);
      let obj = {};
      let hasChanges = false;
      for (let i = 0; i < keys.length; i++) {
        if (
          ["id", "$id", "editable", "loadInitialCurrencyValue"].includes(
            keys[i]
          ) ||
          !data[keys[i]]
        )
          continue;
        if (type == "CREATE") {
          if (data[keys[i]]) {
            hasChanges = true;
            obj[keys[i]] = {
              type: keys[i] == "payorId" ? "String!" : "String",
              value: data[keys[i]],
              unincludeToFields: true,
            };
          }
        } else {
          if (data[keys[i]]) {
            hasChanges = true;
            obj[`update${this.capitalizeFirstLetter(keys[i])}`] = {};
            obj[`update${this.capitalizeFirstLetter(keys[i])}`][keys[i]] = {
              type: "String!",
              value: data[keys[i]],
              unincludeToFields: true,
            };
          }
        }
      }
      return hasChanges ? obj : {};
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getPayload(eob, type) {
      switch (type) {
        case "CREATE":
          return {
            payload: this.parseData(eob, type),
          };

        case "UPDATE":
          return {
            eobDepositInformationId: {
              type: "UUID!",
              value: eob.id,
              unincludeToFields: true,
            },
            collectionQueries: [],
            queries: this.parseData(eob, type),
          };
      }
    },
    formatDate(value, format = "MM/DD/YYYY") {
      if (!value) return null;
      return this.$options.filters.changeDateFormat(value, format);
    },
    populateCurrencies(item) {
      item.loadInitialCurrencyValue = true;
    },
  },
  created() {
    if (this.depositInformations.length !== 0) {
      this.informations = this.depositInformations.map((info) => {
        info["loadInitialCurrencyValue"] = false;
        this.datePicker.unshift({
          cDateShow: false,
          show: false,
          date: "",
          checkDate: "",
        });
        return JSON.parse(JSON.stringify(info));
      });
    }
  },
  watch: {
    depositInformations: function (newVal) {
      this.informations = [...newVal];
    },
  },
};
