import AccessionActions from "modules/actions/accession-actions";
import AddErrorModal from "modules/orders/Accession/modals/AddErrorModal";
import { uuid } from "vue-uuid";

export default {
  name: "ErrorCodes",
  components: {
    AddErrorModal,
  },
  props: {
    insurances: {
      type: Array,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      actions: new AccessionActions(),
      errorModalVisibility: false,
      hideInactive: false,
      onEditState: false,
      totalCount: 0,
      filterBy: "none",
      addedOrderBillingErrorCodes: [],
      orderBillingErrorCodes: [],
      orderBillingErrorCodesCopy: [],
      isLoading: true,
      columns: [
        {
          name: "payorId",
          align: "left",
          text: "Payor ID",
          field: "payorId",
        },
        {
          name: "errorGroup",
          align: "left",
          text: "Error Code / Denial Code Table",
          field: "errorGroup",
        },
        {
          name: "errorCode",
          align: "left",
          text: "Error Code / Denial Code",
          field: "errorCode",
        },
        {
          name: "errorDescription",
          align: "left",
          text: "Error Code / Denial Code Description",
          field: "errorDescription",
        },
        {
          name: "status",
          align: "center",
          text: "Error Code / Denial Code Status",
          field: "status",
          isStatusColumn: true,
        },
        {
          name: "created",
          align: "center",
          text: "Date/Time",
          field: "created",
        },
        {
          name: "active",
          align: "center",
          text: "Action",
          field: "active",
          isCheckbox: true,
          deleteIcon: true,
          width: "50px",
        },
      ],
      paginationOptions: {
        page: 1,
        itemsPerPage: 10,
        totalCount: 0,
        sortOptions: {
          sortBy: "",
          sort: "",
          isDateColumn: false,
        },
        perPageOptions: [
          {
            text: "5",
            value: 5,
          },
          {
            text: "10",
            value: 10,
          },
          {
            text: "15",
            value: 15,
          },
          {
            text: "20",
            value: 20,
          },
        ],
      },
      limitData: this.buildSubQuery("vbsOrderBillingErrorCodes", [
        this.buildSubQuery("payorId", ["insuranceCompanyId"]),
        this.buildSubQuery("billingErrorCode", [
          "errorField",
          "errorGroup",
          "errorCode",
          "hierarchy",
          "errorDescription",
        ]),
        "created",
        "active",
        "isAdded",
        "totalCount",
      ]),
      errorCodesData: null,
      isSavingErrorCodes: false,
      isGeneratingErrorCodes: false,
      isLoadingErrorCodes: false,
      rerunningPricingRules: false,
      tBodyHeight: "2rem",
      progressValue: 10,
    };
  },
  watch: {
    rerunningPricingRules() {
      this.tBodyHeight = `${
        window.document.getElementById("dataTableCol").getBoundingClientRect()
          .height
      }px`;
    },
    hideInactive() {
      this.$refs.dataTable.refetch();
    },
  },
  computed: {
    errorCodesList() {
      let errorCodes = [];
      let pagination = this.$refs.dataTable?.paginationOptions || {};
      let from = pagination
        ? pagination.page == 1
          ? 0
          : pagination.page * 10 - 10
        : 0;

      errorCodes = [
        ...this.addedOrderBillingErrorCodes.slice(
          from,
          pagination.page * 10 || 0
        ),
        ...this.orderBillingErrorCodes,
      ];

      return this.isLoading ? [] : errorCodes;
    },
    btnsDisabled() {
      return (
        this.isSavingErrorCodes ||
        this.isGeneratingErrorCodes ||
        this.rerunningPricingRules ||
        this.isLoading
      );
    },
    _TotalCount() {
      return this.totalCount + this.addedOrderBillingErrorCodes.length;
    },
  },
  methods: {
    getLimitOffset({ limit, offset }) {
      const { paginationOptions } = this.$refs.dataTable;
      const toArray = (this.addedOrderBillingErrorCodes.length / 10)
        .toString()
        .split(".");
      const addedLastPage = toArray[0] >= paginationOptions.page;
      if (parseInt(toArray[0]) && addedLastPage) return { limit: 0, offset: 0 };
      return {
        limit: addedLastPage ? limit - parseInt(toArray[1]) : limit,
        offset: offset - this.addedOrderBillingErrorCodes.length,
      };
    },
    async rerunPricingRules() {
      let resetRerunStates = () => {
        this.isLoading = false;
        this.rerunningPricingRules = false;
        this.progressValue = 0;
        clearInterval(progressInterval);
      };

      let progressInterval = setInterval(() => {
        this.progressValue += 45;
        if (this.progressValue == 90) clearInterval(progressInterval);
      }, 500);

      try {
        this.rerunningPricingRules = true;

        const rerun = await this.actions.runPricingRules({
          orderId: {
            type: "UUID!",
            value: this.$route.params.id,
            unincludeToFields: true,
          },
          asyncProcess: {
            type: "Boolean",
            value: false,
            unincludeToFields: true,
          },
        });

        this.progressValue = 100;
        clearInterval(progressInterval);

        if (rerun.success) {
          this.showNotifyMessage({
            message: "Re-run pricing rules success.",
          });
          setTimeout(() => {
            resetRerunStates();
          }, 500);
        } else {
          this.showNotifyMessage({
            message: rerun.errors[0],
            type: "danger",
          });
          resetRerunStates();
        }
      } catch (error) {
        this.showNotifyMessage({
          message: "Internal server error.",
          type: "danger",
        });
        resetRerunStates();
      }
    },
    async saveAddedErrorCodes() {
      try {
        this.isSavingErrorCodes = true;
        let toAddAddedOrderBillingCodes = [];
        let toUpdateAddedOrderBillingCodes = [];
        const addedErrorCodes = this.errorCodesList.filter(
          (item) => item.isAdded
        );
        if (addedErrorCodes.length) {
          for (let orderBillingErrorCode of addedErrorCodes) {
            const { active, payor, billingErrorCode } = orderBillingErrorCode;

            if (orderBillingErrorCode.id === null) {
              const { errorGroup, errorCode, hierarchy, errorDescription } =
                billingErrorCode;

              let params = {
                errorField: {
                  type: "String!",
                  value: errorCode,
                },
                errorGroup: {
                  type: "BillingErrorGroup!",
                  value: errorGroup,
                },
                errorCode: {
                  type: "String!",
                  value: errorCode,
                },
                hierarchy: {
                  type: "Int!",
                  value: hierarchy,
                },
              };

              if (errorDescription.length)
                params["errorDescription"] = {
                  type: "String",
                  value: errorDescription,
                };

              const result = await this.actions.createBillingErrorCode(params);

              if (result) {
                toAddAddedOrderBillingCodes.push({
                  addAddedOrderBillingCode: {
                    billingErrorCodeId: {
                      type: "UUID!",
                      value: result.id,
                    },
                    payorId: {
                      type: "UUID",
                      value: payor.id,
                    },
                    active: {
                      type: "Boolean!",
                      value: active,
                    },
                  },
                });
              }
            } else {
              if (
                this.orderBillingErrorCodesCopy.find(
                  (item) => item.id == orderBillingErrorCode.id
                ).active != orderBillingErrorCode.active
              )
                toUpdateAddedOrderBillingCodes.push({
                  updateAddedOrderBillingCode: {
                    id: {
                      type: "UUID!",
                      value: orderBillingErrorCode.id,
                    },
                    active: {
                      type: "Boolean!",
                      value: active,
                    },
                  },
                });
            }
          }
        }

        if (
          toAddAddedOrderBillingCodes.length ||
          toUpdateAddedOrderBillingCodes.length
        )
          await this.actions.updateOrderDetails({
            orderId: {
              type: "UUID!",
              value: this.orderId,
              unincludeToFields: true,
            },
            collectionQueries: toAddAddedOrderBillingCodes.concat(
              toUpdateAddedOrderBillingCodes
            ),
          });

        this.isLoading = true
        this.addedOrderBillingErrorCodes = []
        await this.$refs.dataTable.refetch();
        this.showNotifyMessage({
          message: `Added error codes saved.`,
          type: "success",
        });
      } catch (err) {
        this.showNotifyMessage({
          message: `Problem has occurred while saving data.`,
          type: "danger",
        });
      } finally {
        this.isSavingErrorCodes = false;
      }
    },
    addErrorCode(e) {
      try {
        this.addedOrderBillingErrorCodes.push(
          Object.assign(
            {
              uid: uuid.v1(),
              status: e.active ? "Active" : "Deactivated",
              ...e,
            },
            e.billingErrorCode
          )
        );
      } catch (err) {
        this.showNotifyMessage({
          message: `Problem has occurred while saving data.`,
          type: "danger",
        });
      } finally {
        this.errorModalVisibility = false;
      }
    },
    removeErrorCode(uid) {
      const findIndex = this.addedOrderBillingErrorCodes.findIndex(
        (item) => item.uid && item.uid === uid
      );
      if (findIndex > -1) this.addedOrderBillingErrorCodes.splice(findIndex, 1);
    },
    async generateErrorCodes() {
      try {
        this.isLoading = true;
        this.isGeneratingErrorCodes = true;
        await this.actions.updateOrderDetails({
          orderId: {
            type: "UUID!",
            value: this.orderId,
            unincludeToFields: true,
          },
          booleanQueries: ["generateOrderBillingErrorCode"],
        });
        await this.$refs.dataTable.refetch();
      } catch (err) {
        this.showNotifyMessage({
          message: `Problem has occurred while generating error codes.`,
          type: "danger",
        });
      } finally {
        this.isGeneratingErrorCodes = false;
        this.isLoading = false;
      }
    },
    async paginationMethod(pagination) {
      try {
        this.isLoading = true;
        await this.populateErrorCodes(this.orderId, pagination);
      } catch (err) {
        this.showNotifyMessage({
          message: `Problem has occurred while generating error codes.`,
          type: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async populateErrorCodes(accessionId, data = {}) {
      if (data.limit || data.offset) data = this.getLimitOffset(data);

      const result = await this.actions.getOrderEntryById({
        variables: {
          property: {
            id: {
              type: "UUID!",
              value: accessionId,
            },
            ["vbsOrderBillingErrorCodes.orderByDate"]: {
              type: "DateOrderSort",
              value: {
                dateField: "CREATED",
                orderBy: "DESC",
              },
            },
            ["vbsOrderBillingErrorCodes.limit"]: {
              type: "Int!",
              value: data.limit || 10,
            },
            ["vbsOrderBillingErrorCodes.offset"]: {
              type: "Int!",
              value: data.offset < 0 ? 0 : data.offset || 0,
            },
            ["vbsOrderBillingErrorCodes.excludeInactive"]: {
              type: "Boolean",
              value: this.hideInactive,
            },
          },
        },
        limitData: [
          this.buildSubQuery("vbsOrderBillingErrorCodes", [
            this.buildSubQuery("payorId", ["insuranceCompanyId"]),
            this.buildSubQuery("billingErrorCode", [
              "errorField",
              "errorGroup",
              "errorCode",
              "hierarchy",
              "errorDescription",
            ]),
            "created",
            "active",
            "isAdded",
            "totalCount",
          ]),
          "billedAmount",
          "balanceDue",
          "priceDate",
          "billingAccessionStatus",
        ],
      });

      this.totalCount = result.vbsOrderBillingErrorCodes[0]?.totalCount;
      if (result) {
        const { billedAmount, balanceDue, priceDate, billingAccessionStatus } =
          result;
        this.$emit("updateAccessionDetails", {
          billedAmount: billedAmount || "",
          balanceDue: balanceDue || "",
          priceDate: priceDate || "",
          billingAccessionStatus: billingAccessionStatus || "",
        });
      }
      // set order billing error codes
      this.orderBillingErrorCodes =
        result.vbsOrderBillingErrorCodes !== null &&
        result.vbsOrderBillingErrorCodes.length
          ? result.vbsOrderBillingErrorCodes.map((item) => {
              const {
                id,
                active,
                payorId,
                billingErrorCode,
                created,
                isAdded,
              } = item;

              const { errorCode, errorDescription, errorGroup, hierarchy } =
                billingErrorCode;

              return {
                id,
                active,
                created: this.$options.filters.getTimeDate(
                  created,
                  "MM/DD/YYYY hh:mm A"
                ).zone,
                isAdded,
                status: active ? "Active" : "Deactivated",
                hasCheckbox: isAdded,
                errorCode: errorCode || "",
                errorGroup: errorGroup || "",
                errorDescription: errorDescription || "",
                payorId: payorId?.insuranceCompanyId || "N/A",
                billingErrorCode: {
                  id: billingErrorCode.id,
                  errorCode,
                  errorDescription,
                  errorGroup,
                  hierarchy,
                },
                payor:
                  payorId !== null
                    ? {
                        id: payorId.id,
                        payorId: payorId.insuranceCompanyId,
                      }
                    : {
                        id: null,
                        payorId: "",
                      },
              };
            })
          : [];

      this.orderBillingErrorCodesCopy = JSON.parse(
        JSON.stringify(this.orderBillingErrorCodes)
      );

      // set added order billing error codes
    },
    isRemovable(item) {
      return item.deletable && item.id === null;
    },
    isExistingAddedErrorCode(item) {
      return item.deletable && item.id !== null;
    },
    hideInactiveItems(e) {
      if (e) {
        this.filterBy = "active";
      } else this.filterBy = "none";
    },
    filterItems(value, search, item) {
      return search.toUpperCase() === "ACTIVE" ? item.active : true;
    },
    cancel() {
      this.onEditState = false;

      this.orderBillingErrorCodes = JSON.parse(
        JSON.stringify(this.orderBillingErrorCodesCopy)
      );
    },
    disablePaginationButton(button) {
      if (button == "first") return this.paginationOptions.page == 1;
      return (
        this.paginationOptions.page >=
        this.errorCodesList.length / this.paginationOptions.itemsPerPage
      );
    },
    totalPages(itemsPerPage) {
      const hasRemainder = this.errorCodesList.length % itemsPerPage != 0;
      return (
        Math.floor(this.errorCodesList.length / itemsPerPage) +
        (hasRemainder ? 1 : 0)
      );
    },
  },
  async created() {
    await this.generateErrorCodes();
  },
};
