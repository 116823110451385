import AccessionActions from "modules/actions/accession-actions";
import Actions from "modules/actions/client-invoice-actions";

const COLUMNS = [
  {
    text: "Date",
    align: "left",
    sortable: false,
    field: "date",
  },
  {
    text: "Payor ID",
    align: "left",
    sortable: false,
    field: "payorId",
  },
  {
    text: "Payor Priority",
    align: "left",
    sortable: false,
    field: "payorPriority",
  },
  {
    text: "File Type",
    align: "left",
    sortable: false,
    field: "fileType",
  },
  {
    text: "File Name",
    align: "left",
    sortable: false,
    field: "filename",
    buttonLink: {
      click: async ({ client_invoice_id, revision_number }) => {
        try {
          const url = await invoiceActions.viewClientInvoice({
            property: {
              clientInvoiceId: {
                type: "UUID!",
                value: client_invoice_id,
              },
              revisionNumber: {
                type: "Int",
                value: revision_number,
              },
            },
          });
          window.open(url);
        } catch(error) {
          this.showNotifyMessage({
            message: "Problem has occurred while generating link.",
            type: "danger",
          });
        }
      },
    }
  },
  {
    text: "File Status",
    align: "left",
    sortable: false,
    field: "fileStatus",
  },
  {
    text: "Reference Number",
    align: "left",
    sortable: false,
    field: "referenceNumber",
  },
];

const actions = new AccessionActions();
const invoiceActions = new Actions();

export default {
  name: "BillingHistory",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    initialDataLoading: false,
    isDataLoading: false,
    columns: COLUMNS,
    billingHistory: [],
    totalCount: 0,
  }),
  methods: {
    async fetchBillingHistory() {
      try {
        this.loading(true);
        const result = await actions.getAccessionEntryById({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: this.id,
              },
            },
          },
          limitData: [
            this.buildSubQuery("billingHistory", [
              this.buildSubQuery("entries", [
                "dateCreated",
                "payorId",
                "payorPriority",
                this.buildSubQuery("fileType", [
                  "enum",
                  "label",
                ], false),
                "filename",
                "fileStatus",
                "fileDetails",
                "referenceNumber",
                "totalCount",
              ], false),
            ], false),
          ],
        });
        if (result) {
          const { billingHistory } = result;
          this.billingHistory = billingHistory?.entries?.map(entry => ({
            ...entry,
            date: entry?.dateCreated
              ? this.$options.filters.getTimeDate(entry.dateCreated, "MM/DD/YYYY")
                  .zone
              : "",
            fileType: entry?.fileType?.label || null,
            link: entry?.fileDetails
              ? JSON.parse(entry.fileDetails)
              : null,
          }));
          this.totalCount = billingHistory?.entries?.[0]?.totalCount || 0;
          this.loading(false);
        } else {
          this.loading(false);
          this.showNotifyMessage({
            message: "Problem has occurred while fetching data.",
            type: "danger",
          });
        }
      } catch(error) {
        this.loading(false);
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
    loading(bool) {
      this.isDataLoading = bool;
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.initialDataLoading = true;
      await this.fetchBillingHistory();
      this.initialDataLoading = false;
    });
  },
};
