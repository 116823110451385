import AccessionActions from "modules//actions/accession-actions";

export default {
  name: "AccessionDetails",
  components: {},
  props: {
    orderId: String,
    internalId: String,
    requisitionId: String,
    isMedicare: Boolean,
    abn: { type: String, required: true },
    sample: { type: Object, required: true },
    payorAccount: { type: Object, required: true },
    dateSampleTaken: { type: String, required: true },
    dateFirstTransmitted: { type: String, required: true },
    billingNetsuiteStatus: { type: String, required: true },
    netsuiteInvoice: { type: String, required: true },
    billedAmount: { type: String, required: true },
    balanceDue: { type: String, required: true },
    priceDate: { type: String, required: true },
    firstName: { type: String, required: true },
    lastName: { type: String, required: true },
    dateOfBirth: { type: String, required: true },
    gender: { type: [String, null], required: true },
    billingAccessionStatus: { type: String, required: true },
    billingStatementStatus: { type: String, required: true },
    billType: { type: String, required: true },
    defaultData: { type: Object, required: true },
  },
  data() {
    return {
      actions: new AccessionActions(),
      isSaving: false,
      onEditState: false,
      datePicker: {
        bDateShow: false,
        pDateShow: false,
      },
      loadingMesg: "Updating accession details...",
      abnOptions: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      items: [
        {
          title: "yes",
        },
        {
          title: "no",
        },
      ],
      genderOptions: [
        {
          text: "Male",
          value: "Male",
        },
        {
          text: "Female",
          value: "Female",
        },
      ],
      billTypeOptions: [],
      MMM: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      selectOptions: {
        accessionStatus: [
          {
            text: "Billing Review",
            value: "BillingReview",
          },
          {
            text: "Unpriceable",
            value: "Unpriceable",
          },
          {
            text: "Unbillable",
            value: "Unbillable",
          },
          {
            text: "Priced",
            value: "Priced",
          },
          {
            text: "Billed",
            value: "Billed",
          },
          {
            text: "Priced With Errors",
            value: "PricedWithErrors",
          },
          {
            text: "Posting",
            value: "Posting",
          },
          {
            text: "Zero Balance",
            value: "ZeroBalance",
          },
          {
            text: "Credit Balance",
            value: "CreditBalance",
          },
        ],
        statementStatus: [
          {
            text: "Generating",
            value: "Generating",
          },
          {
            text: "Success",
            value: "Success",
          },
          {
            text: "Failed",
            value: "Failed",
          },
          {
            text: "TP",
            value: "TP",
          },
          {
            text: "CLSTMT",
            value: "CLSTMT",
          },
          {
            text: "PTSTMT",
            value: "PTSTMT",
          },
        ],
      },
      rules: {
        gender: {
          validation: false,
          required: [(val) => this.validateGender(val)],
        },
        amount: [
          (val) =>
            !val ||
            /^\d+(\.\d{1,2})?$/.test(val) ||
            "Input invalid (eg. 123.45)",
        ],
        required: [(val) => !!val || "This field is required."],
        birthDate: [
          (val) => !!val || "This field is required.",
          (val) =>
            this.isNotFutureDate(val) || "Birth date is beyond the present.",
        ],
        nonSpecialString: [
          (val) => !!val || val.length !== 0 || "This field is required.",
          (val) =>
            /^[a-zA-Z0-9 ]*$/.test(val) || "Should not have special characters",
        ],
      },
      date: {
        dateOfBirth: "",
        priceDate: "",
      },
      confirmChangesModal: {
        isVisible: false,
        message: "Are you sure you want to save the updates?",
      },
    };
  },
  computed: {
    __Abn: {
      get() {
        return this.abn;
      },
      set(value) {
        this.$emit("update:abn", value || "");
      },
    },
    __FirstName: {
      get() {
        return this.firstName;
      },
      set(value) {
        this.$emit("update:firstName", value);
      },
    },
    __LastName: {
      get() {
        return this.lastName;
      },
      set(value) {
        this.$emit("update:lastName", value);
      },
    },
    __DateOfBirth: {
      get() {
        this.date.dateOfBirth = this.formatDate(this.dateOfBirth, "YYYY-MM-DD");
        return this.dateOfBirth;
      },
      set(value) {
        this.$emit("update:dateOfBirth", value);
      },
    },
    __Gender: {
      get() {
        return this.gender;
      },
      set(value) {
        this.$emit("update:gender", value || "");
      },
    },
    __AccessionStatus: {
      get() {
        return this.billingAccessionStatus;
      },
      set(value) {
        this.$emit("update:billingAccessionStatus", value);
      },
    },
    __StatementStatus: {
      get() {
        return this.billingStatementStatus;
      },
      set(value) {
        this.$emit("update:billingStatementStatus", value);
      },
    },
    __BilledAmount: {
      get() {
        return this.formatCurrency(this.billedAmount);
      },
      set(value) {
        this.$emit("update:billedAmount", value);
      },
    },
    __BalanceDue: {
      get() {
        return this.formatCurrency(this.balanceDue);
      },
      set(value) {
        this.$emit("update:balanceDue", value);
      },
    },
    __PriceDate: {
      get() {
        this.date.priceDate = this.formatDate(this.priceDate, "YYYY-MM-DD");
        return this.priceDate;
      },
      set(value) {
        this.$emit("update:priceDate", value);
      },
    },
    __NetsuiteStatus: {
      get() {
        return this.billingNetsuiteStatus;
      },
      set(value) {
        this.$emit("update:billingNetsuiteStatus", value);
      },
    },
    __NetsuiteInvoice: {
      get() {
        return this.netsuiteInvoice;
      },
      set(value) {
        this.$emit("update:netsuiteInvoice", value);
      },
    },
    __BillType: {
      get() {
        return this.billType;
      },
      set(value) {
        this.$emit("update:billType", value);
      },
    },
    shouldDisableField() {
      return !this.onEditState || this.isSaving;
    },
    disabledByAccessionStatus() {
      if (!this.billingAccessionStatus) return false;
      return ["BILLED", "PRICED"].includes(
        this.billingAccessionStatus?.toUpperCase()
      );
    },
  },
  methods: {
    validateGender(val) {
      if (!val) {
        this.rules.gender.validation = true;
        return false;
      }
      this.rules.gender.validation = false;
      return true;
    },
    formatDate(value, format = "MM/DD/YYYY") {
      if (!value) return "";
      return this.$options.filters.changeDateFormat(value, format);
    },
    cancelChanges() {
      this.$emit("cancelChanges", { section: "accessionDetails" });
      this.onEditState = false;
    },
    updateChanges() {
      this.$emit("updateDetailsChanges", Object.keys(this._props));
    },
    checkFormChanges() {
      const editableFields = [
        "firstName",
        "lastName",
        "dateOfBirth",
        "gender",
        "abn",
        "billingStatementStatus",
        "billingNetsuiteStatus",
      ];
      let hasChanges = false;
      editableFields.forEach(field => {
        if (this[field] !== this.defaultData[field]) {
          hasChanges = true;
        }
      });
      return hasChanges;
    },
    async saveAccessionDetails() {
      this.isSaving = true;
      if (this.checkFormChanges()) {
        this.confirmChangesModal.isVisible = true;
      } else {
        this.showNotifyMessage({
          message: "No changes were made.",
          type: "danger",
        });
        this.isSaving = false;
      }
    },
    async updateAccession() {
      this.confirmChangesModal.isVisible = false;
      try {
        if (!this.$refs.form.validate()) return;
        let collectionQueries = [
          {
            setBillingAccessiontStatus: {
              data: {
                value: this.billingAccessionStatus,
                type: "BillingAccessionStatus",
              },
            },
            setNetsuiteInvoice: {
              data: {
                value: this.netsuiteInvoice,
                type: "String",
              },
            },
            setBilledAmount: {
              data: {
                value: this.billedAmount,
                type: "String",
              },
            },
            setBalanceDue: {
              data: {
                value: this.balanceDue,
                type: "String",
              },
            },
            setAbn: {
              data: {
                value: this.abn,
                type: "String",
              },
            },
          },
        ];

        if (this.billingStatementStatus)
          collectionQueries.push({
            setBillingStatementStatus: {
              data: {
                value: this.billingStatementStatus,
                type: "BillingStatementStatus",
              },
            },
          });

        if (this.__BillType && this.__BillType.length)
          collectionQueries.push({
            updateBillType: {
              billType: {
                type: "BillType!",
                value: this.__BillType,
              },
            },
          });

        if (this.billingNetsuiteStatus)
          collectionQueries.push({
            setBillingNetsuiteStatus: {
              data: {
                value: this.billingNetsuiteStatus,
                type: "String",
              },
            },
          });

        if (this.priceDate)
          collectionQueries.push({
            setPriceDate: {
              data: {
                value: this.priceDate,
                type: "String",
              },
            },
          });

        if (this)
          await this.actions.updateAccessionDetails({
            collectionQueries,
            childQueries: [
              {
                patient: {
                  updateName: {
                    firstName: {
                      value: this.firstName,
                      type: "String",
                    },
                    lastName: {
                      value: this.lastName,
                      type: "String",
                    }
                  },
                  updateDateOfBirth: {
                    dateOfBirth: {
                      value: this.dateOfBirth,
                      type: "String!",
                    },
                  },
                  updateGender: {
                    gender: {
                      value: this.gender,
                      type: "Gender!",
                    },
                  },
                },
              },
            ],
            orderId: {
              value: this.orderId,
              type: "UUID!",
              unincludeToFields: true,
            },
          });
        this.$emit("updateDetailsChanges", Object.keys(this._props));
        this.showNotifyMessage({
          message: "Details have been updated.",
          type: "success",
        });
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      } finally {
        this.isSaving = false;
        this.onEditState = false;
      }
    },
    async getBillTypes() {
      try {
        if (!this.billTypeOptions.length) {
          this.billTypeOptions = [
            {
              text: "Fetching...",
              disabled: true,
            },
          ];

          const result = await this.actions.getEnumValues("BillType");

          if (result)
            this.billTypeOptions = result.enumValues.map((item) => {
              return {
                text: item.name,
                value: item.name,
              };
            });
        }
      } catch (err) {
        this.showNotifyMessage({
          message: `Unable to fetch data.`,
          type: "danger",
        });
      }
    },
    selectedBillType(e) {
      this.__BillType = e;
    },
  },
  async mounted() {
    if (this.__BillType && this.__BillType.length) await this.getBillTypes();
  },
};
